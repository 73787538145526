import React, { useReducer } from "react";
import { Reducer } from "../reducer/Reducer";

export const ExchangeContext = React.createContext();
//"RUB", "EUR"
const initialState = {
  currencyAll: ["ETH", "ETC", "BTC", "TRX", "DOGE", "SOL", "DOT", "XRP", "ADA"],
  currency: ["USDT","RUB"],
  cryptoCurrency: ["ETH", "ETC", "BTC", "TRX", "DOGE", "SOL", "DOT", "XRP", "ADA"],
  firstCurrency: null,
  secondCurrency: null,
  firstCurrencyInner: null,
  secondCurrencyInner: null,
  amount: 0,
  resalt: 0,
  toWallet:null,
  ourWallet : [
    {
      coin:'RUB',
      wallet:'rub'
    },
    {
      coin:'EUR',
      wallet:'eur'
    },
    {
      coin:'USDT (TRC20)',
      wallet:'TChbzNWayMGrASQfmQnSApY7DjEJmWDLgm'
    },
    {
      coin:'USDT (ERC20)',
      wallet:'0xF14a3E5f987F79c0644f69d98231050e91Fac370'
    },
    {
      coin:'USD',
      wallet:'usd'
    },
    {
      coin:'ETH',
      wallet:'0xF14a3E5f987F79c0644f69d98231050e91Fac370'
    },
    {
      coin:'ETC',
      wallet:'0x33F40c6D9C4C12BF3FdA0f566E5E023308603504'
    },
    {
      coin:'BTC',
      wallet:'bc1q7azq53kwzz3wmf5p2qkh2ldt9c2s3z5wkg2kky'
    },
    {
      coin:'TRX',
      wallet:'TChbzNWayMGrASQfmQnSApY7DjEJmWDLgm'
    },
    {
      coin:'DOGE',
      wallet:'DPabhQ5ThemNWg7pCoNLreV6o3Ape5b9N2'
    },
    {
      coin:'SOL',
      wallet:'HL3w7RaPnfnTzjtvQW33XswW7NNohL1qNB1Es2McF92G'
    },
    {
      coin:'DOT',
      wallet:'15oHoAhH9BQWobqSWtNxC1bGoRHdL5qKt2WKvtU5hypCPcbK'
    },
    {
      coin:'XRP',
      wallet:'rPqLhCAREdMRMTrq7EPfykJ4dNHSYPSGYt'
    },
    {
      coin:'ADA',
      wallet:'addr1qxpql2f7sjjvgh90vzxza9zn98txyztlp64epwl5q3f4663fjq40puxelh2t4gfa9h94h92d5xcpmmt688cckqdn93hqktfp0d'
    },
  ]
};

export const ExchangeProvider = ({ children }) => {
  const [value, dispatch] = useReducer(Reducer, initialState);
  value.setFirstCurrency = (first) => {
    dispatch({ type: "setFirstCurrency", payload: first });
  };
  value.setSecondCurrency = (second) => {
    dispatch({ type: "setSecondCurrency", payload: second });
  };
  value.setFirstCurrencyInner = (firstIn) => {
    dispatch({ type: "setFirstCurrencyInner", payload: firstIn });
  };
  value.setSecondCurrencyInner = (secondIn) => {
    dispatch({ type: "setSecondCurrencyInner", payload: secondIn });
  };
  value.setResalt = (res) => {
    dispatch({ type: "setResalt", payload: res });
  };
  value.setAmount = (data) => {
    dispatch({ type: "setAmount", payload: data });
  };
  value.setToWallet = (data) => {
    dispatch({ type: "setToWallet", payload: data });
  };

  return <ExchangeContext.Provider value={value}>{children}</ExchangeContext.Provider>;
};
