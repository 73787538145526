import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { IconShieldCheck } from "../utils/Icons";
import Footer from "../components/Footer";

const Policy = (props) => {
  // Translation
  const { t } = useTranslation();
  const basePath = "policy.";

  let policyContent = [].concat(t(basePath + "policyContent", { returnObjects: true }));

  return (
    <>
      <section className="policy">
        <Container>
          <Row className="align-items-center">
            <Col lg={12}>
              <div class="title">
                <IconShieldCheck width="30px" height="30px" fill="#fb725a" />
                <h1>
                  {t(basePath + "title.main")} <b>{t(basePath + "title.sub")}</b>
                </h1>
              </div>
            </Col>
            <Col lg={12}>
              {policyContent.map((item, i) => (
                <div className="item" key={i}>
                  <h4>
                    <span>{item.number}</span>
                    {item.title}
                  </h4>
                  {item.desc.map((item, i) => (
                    <p key={i}>{item.title}</p>
                  ))}
                </div>
              ))}
            </Col>
          </Row>
        </Container>
        <svg className="wave-firts" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,64L120,53.3C240,43,480,21,720,21.3C960,21,1200,43,1320,53.3L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
        <svg className="wave-second" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
          <path
            fill="#000"
            fillOpacity="1"
            d="M0,64L120,53.3C240,43,480,21,720,21.3C960,21,1200,43,1320,53.3L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </section>
      <Footer />
    </>
  );
};

export default Policy;
