export const TRANSLATIONS_DE = {
  navBar: {
    link: {
      home: "Heimat",
      promo: "Gutscheincode",
      faq: "FAQ",
    },

    lang: {
      title: "Sprache",
      "en-US": "Englisch",
      "ru-RU": "Russisch",
      "de-DE": "Deutsch",
    },

    btn: "Austausch",
    etention: "Перед отправкой проверьте правильность Ваших реквизитов получения!"
  },

  home: {
    banner: {
      title: "Möchten Sie den besten Wechselkurs erhalten?",
      desc: "Wir freuen uns, einen zuverlässigen, profitablen, sofortigen und bequemen Service für die Einzahlung / Auszahlung elektronischer Währungen anbieten zu können.",
      btn: "Austausch",
    },

    exchenge: {
      title: {
        main: "Geldwechsel",
        sub: "",
      },

      give: "Ich gebe",
      receive: "Ich erhalte",
      amount: "Menge",
      choose: "Währung",
      btn: "Austausch",

      agreement: {
        first: "Indem Sie auf die Schaltfläche „Exchange“ klicken, stimmen Sie zu",
        link: "Nutzungsbedingungen",
        second: "unsere Ressource",
      },

      modalError: {
        title: "Fehler! Bitte fülle alle Felder aus.",
      },

      modalSuccess: {
        title: "Bewerbung gesendet! Bitte überprüfen Sie Ihre Post"
      },

      modalSend: {
        title: "Füllen Sie das Formular aus, um eine Bewerbung zu senden!",
        form: {
          name: "Gib deinen Namen ein *",
          number: "Gib deine Telefonnummer ein *",
          email: "Geben sie ihre E-Mail Adresse ein @gmail.com*",
          wallet: "Geben Sie Ihre Brieftasche ein *",
          promo: "Aktionscode eingeben (falls verfügbar)",
        },
        error: "Füllen Sie alle Eingabefelder aus, e-mail @gmail.com",
        success: "Die Bewerbung wurde gesendet, warten Sie auf eine Antwort des Managers",
        btn: "Senden Sie eine Anfrage",
      },

      feature: {
        course: "Der profitabelste Kurs",
        refill: "Ab 500 rub Nachschub",
        commission: "0.5% - 1% Provision",
        support: "Unterstützung rund um die Uhr",
      },
    },

    advantage: {
      title: {
        small: "unsere Vorteile",
        main: "Warum uns",
        sub: "wählen?",
      },

      safely: {
        title: "Sicher",
        desc: "Bei uns müssen Sie sich keine Sorgen um die Sicherheit Ihrer Gelder machen.",
      },
      bonuses: {
        title: "Boni",
        desc: "Täglicher Aktionscode-Rabatt auf Provision in Höhe von 1-3%",
      },
      fast: {
        title: "Schnell",
        desc: "Wir bieten einen schnellen Austausch Ihrer Gelder",
      },
      support: {
        title: "Schnelle Unterstützung",
        desc: "Unsere Manager sind bereit, Sie professionell und schnell zu unterstützen",
      },
    },

    about: {
      title: {
        small: "Über das Unternehmen",
        main: "Profitabel und bequem",
        sub: "Service",
      },

      desc: {
        first:
          "Der Währungsumrechner bietet die Möglichkeit, den gewünschten Betrag online von einer Währung in eine andere umzurechnen, zum Beispiel Dollar in Euro, Dollar in Rubel, Euro in Schweizer Franken.",
        second:
          "Der Konverter führt alle Umrechnungen auf der Grundlage offizieller Daten zu Notierungen internationaler Währungen der Zentralbank der Russischen Föderation durch.",
        third: "Informationen über Währungskurse werden stündlich aktualisiert.",
        fourth: "Der Online-Währungsumrechner ist sehr einfach zu bedienen und spart Ihnen Zeit.",
      },
    },
  },

  promo: {
    title: {
      small: "Gutscheincode",
      main: "Ihr Rabatt-Aktionscode",
    },
    time: "Gültig bis 22.07.2023",
  },

  faq: {
    title: "Häufig gestellte Fragen",
    faqContent: [
      {
        title: "Wie funktioniert der Austauscher?",
        desc: "Der Austauscher ist vollautomatisch. Sie können 24/7/365 austauschen. Die Zahlung erfolgt sofort, nachdem Ihr Geld unserem Wallet gutgeschrieben wurde. Informationen über den Status des Antrags werden auf der Zahlungsseite aktualisiert und per E-Mail dupliziert.",
      },
      {
        title: "Verwenden Sie die AML-Validierung?",
        desc: "Ja, wir verwenden AML, um Kryptowährungen mit dem „Crystal Blockchain“-System unseres Partners auf Risiken zu prüfen. Diese Prüfung identifiziert Kryptowährungen, die ihren Eigentümern gestohlen, in betrügerischer Absicht erhalten, durch einen „Mixer“ geleitet wurden oder am Verkauf verbotener Waren auf Darknet-Sites beteiligt waren. Wird eine solche Kryptowährung von unserem System erkannt, erhält Ihre Bewerbung den Status „Riskante Zahlung“.",
      },
      {
        title: "Was sind die Bedingungen für die Gutschrift von Geldern?",
        desc: 'Die Zahlung erfolgt sofort, nachdem Ihr Geld unserem Wallet gutgeschrieben wurde. Wenn sich der Antrag im Status "Abgeschlossen" befindet, wurde das Geld von unserem Konto abgebucht und an die von Ihnen angegebenen Daten gesendet.',
      },
      {
        title: "Kann ich die Bewerbung stornieren?",
        desc: "Sie können die Anwendung stornieren, indem Sie an den technischen Support-Chat auf der Website schreiben. In diesem Fall erfolgt die Rückgabe mit einer Serviceprovision von 10 % und nachdem Ihr Geld unserem Wallet gutgeschrieben wurde. Eine abgeschlossene Bewerbung kann nicht storniert werden.",
      },
      {
        title: "Legen Sie den Wechselkurs fest?",
        desc: "Ja, die Rate ist für Bitcoin auf 90 Minuten und für andere Kryptowährungen auf 30 Minuten festgelegt. Nach dieser Zeit behält der Antrag seine Gültigkeit und wird gemäß den Bedingungen der Börse ausgeführt.",
      },
      {
        title: "Was passiert mit dem Antrag, wenn die Frist für die Festsetzung des Satzes verstrichen ist?",
        desc: "Der Antrag bleibt gültig und wird nach Erhalt der Bestätigungen in der Blockchain automatisch ausgeführt. Fällt der Wechselkurs, erhalten Sie eine E-Mail mit Informationen, um den Antrag selbstständig zum aktuellen Wechselkurs neu zu berechnen oder eine Rückerstattung zu veranlassen. Wenn Sie innerhalb von 12 Stunden keine Entscheidung treffen, wird der Antrag automatisch zum aktuellen Kurs neu berechnet. Steigt der Kurs, wird der Auftrag automatisch zum Kurs zum Zeitpunkt seiner Erstellung ausgeführt.",
      },
      {
        title: "Wie sind die Bewerbungsstatus?",
        desc: 'Der Status ändert sich während des Austauschs und ist auf der Zahlungsseite verfügbar. Außerdem werden Status in der Mail dupliziert. „Warten auf Zahlung“ – Der Antrag wurde erstellt, aber das Geld wurde unserem Wallet noch nicht gutgeschrieben. „Warten auf Bestätigung“ – die Transaktion ist in die Blockchain eingetreten und wartet auf die Bestätigung der Miner im Netzwerk. „Abgeschlossen“ – Das Geld wurde gemäß den Vorschriften an die von Ihnen angegebenen Details gesendet, es wird eine Gutschrift erwartet. "Geschlossen" - Wir haben keine Zahlung für die Bewerbung erhalten, die Bewerbung wird nach Ablauf der Frist geschlossen. Bei Zahlungseingang wird der Antrag ausgeführt. "Bezahlt. Der Kurs hat sich geändert" - Der Zeitpunkt der Festsetzung des Kurses ist abgelaufen und er hat sich verringert. Sie müssen neu berechnen oder eine Rückerstattung veranlassen. "Riskante Zahlung" - Ihre Kryptowährung wurde zuvor auf verbotenen Darknet-Sites verwendet oder durch den "Mixer" geleitet. Bitte veranlassen Sie eine Rückerstattung. "Ungültiger Zahlungsbetrag" - Sie haben weniger als den Mindestbetrag für den Umtausch bezahlt. Bitte veranlassen Sie eine Rückerstattung. "Zahlung abgelehnt" - Wir konnten möglicherweise aufgrund von Limits kein Geld an die angegebenen Details senden. Sie können die Zahlung selbst wiederholen oder die Kartennummer in Ihrem persönlichen Konto oder über einen an Ihre E-Mail gesendeten Link ändern. "Teilweise bezahlt" - wir konnten einen Teil der Gelder nicht an die angegebenen Details senden, möglicherweise aufgrund von Limits. Sie können die Zahlung selbst wiederholen oder die Kartennummer in Ihrem persönlichen Konto oder über einen an Ihre E-Mail gesendeten Link ändern.',
      },
      {
        title: "„Bestätigung ausstehend“ – was ist das und wer soll bestätigen?",
        desc: "Eine Kryptowährungsübertragung gilt als abgeschlossen, wenn sie die erforderliche Anzahl von Bestätigungen im Blockchain-Netzwerk erhält. Nach 2 Bestätigungen für Bitcoin und 6 für Ethereum und andere Kryptowährungen erfolgt eine automatische Zahlung. Die Geschwindigkeit, mit der Sie Bestätigungen erhalten, wird durch die von Ihnen festgelegte Provision für die Überweisung und die Netzwerklast beeinflusst. Da es nicht mehr möglich ist, eine Transaktion abzubrechen und deren Geschwindigkeit zu beeinflussen, bleibt nur noch das Warten auf die Bestätigung.",
      },
      {
        title: "Ich habe weniger bezahlt als im Antrag angegeben.",
        desc: "Kein Problem. Nach Bestätigungen in der Blockchain erfolgt eine automatische Neuberechnung des bei uns eingegangenen Betrags unter Berücksichtigung der Kursdifferenz, falls diese fällt.",
      },
      {
        title: '"Riskante Zahlung" - was ist das und was ist zu tun?',
        desc: "Alle Börsen sowie einige Ressourcen verwenden eine Kryptowährungs-Risikoprüfung. Wir verwenden das Spitzensystem „Crystal Blockchain“. Der Status „Riskante Zahlung“ bedeutet, dass der größte Teil der Kryptowährung, die Sie uns gesendet haben, zuvor am Verkauf verbotener Waren auf Darknet-Marktplätzen beteiligt war, durch den „Mixer“ geleitet wurde, um Geld zu waschen, oder gestohlen wurde. Wenn der Antrag diesen Status erreicht hat, müssen Sie auf der Zahlungsseite oder über den Link im Brief in der E-Mail selbstständig eine Rückerstattung veranlassen.",
      },
      {
        title: "Ich habe bezahlt und die Anwendung geschlossen.",
        desc: "Überprüfen Sie zunächst die Auszahlung der Währung aus Ihrer Brieftasche. Denken Sie auch daran, dass einige Speicherressourcen für Kryptowährungen mit Verzögerung senden. In diesem Fall ist der Status der Anwendung „Geschlossen“ temporär und wird ausgeführt, nachdem die Währung unserem Wallet gutgeschrieben wurde.",
      },
      {
        title: "Was ist die Rücksendegebühr?",
        desc: 'Die Provision für die Rückgabe der Kryptowährung beträgt 10 USDT, 0,003 ETH, 0,0003 BTC. Bei "Risikozahlung" erfolgt die Rückgabe abzüglich der aktuellen Provision in der Blockchain, wenn der Antrag auf Initiative des Kunden storniert wird , erfolgt die Rückgabe mit einer Serviceprovision von 10% und nach Gutschrift Ihres Geldes auf unserer Wallet.Die Rückgabe einer Kryptowährung, mit der unser Service nicht funktioniert, oder Coins, die versehentlich an das falsche Netzwerk gesendet wurden, erfolgt abzüglich 10 % des Wechselbetrags.',
      },
      {
        title: "Ich habe beim Bezahlen der Anwendung einen Fehler mit dem Netzwerk/der Kryptowährung gemacht.",
        desc: "Unsere Experten werden einen konkreten Fall prüfen und Sie innerhalb von 24 Stunden über die Möglichkeit der Rückgabe einer solchen Kryptowährung informieren. Bei technischer Möglichkeit erfolgt die Rücksendung innerhalb von 10 Tagen abzüglich 10 % der Antragssumme.",
      },
    ],
  },

  copy: {
    copy: "Kopieren",
    success: "Kopiert",
  },

  policy: {
    title: {
      main: "Datenschutz -",
      sub: "Bestimmungen",
    },
    policyContent: [
      {
        number: "1",
        title: "Hauptsächlich",
        desc: [
          {
            title:
              "1.1. Bitte lesen Sie die folgenden Bedingungen bezüglich der von Obmen-House bereitgestellten Informationsspeicherdienste. Obmen-House behält sich das Recht vor, die Datenschutzrichtlinie jederzeit ohne vorherige Ankündigung zu ändern. Nur die Online-Datenschutzerklärung ist gültig, also lesen Sie bitte regelmäßig die Datenschutzerklärung der Website. Diese Bestimmungen wurden von unserem Team entwickelt, um zu zeigen, dass sich der Obmen-House-Service um die Sicherheit der Informationen seiner Kunden kümmert. Wir möchten zeigen, dass das Speichern von Informationen mit https://obmen-house.io absolut sicher ist. Nachfolgend können Sie die Datenschutzerklärung lesen.",
          },
          {
            title:
              "1.2. Der Obmen-House-Dienst erhält personenbezogene Daten vom Benutzer während der Registrierung oder beim Stellen eines Antrags und speichert sie in der Datenbank.",
          },
          {
            title:
              "1.3. Der Dienst verwendet Cookies, Daten über die IP-Adresse des Benutzers, Informationen über den Browser des Benutzers für den Betrieb der Software und Hardware der Website.",
          },
          {
            title:
              "1.4. Obmen-House hat das Recht, zusätzliche Informationen über die personenbezogenen Daten des Benutzers anzufordern.",
          },
          {
            title:
              "1.5. Die personenbezogenen Daten des Benutzers können vom Dienst ausschließlich zum Zweck der Sicherstellung des Betriebs des Dienstes verwendet werden und dürfen nicht an Dritte weitergegeben werden, es sei denn, dies ist gesetzlich vorgeschrieben, die Regeln der Wechselstuben für ein bestimmtes EPS und internationales Recht.",
          },
        ],
      },
      {
        number: "2",
        title: "Persönliche Daten der Benutzer",
        desc: [
          {
            title:
              "2.1. Bei der Registrierung hinterlassen Benutzer einige Informationen, nämlich: Login, Passwort, Name, Kontaktinformationen, z. B. E-Mail-Adresse. Diese Informationen werden verwendet, um die Obmen-House-Dienste bereitzustellen. Registrierungsinformationen (Login, Passwort) - zur Benutzerauthentifizierung werden Kontaktinformationen (Name, E-Mail-Adresse) verwendet, um Benutzer über neue Funktionen des Systems zu informieren. Wir geben ohne Ihre Zustimmung keine Kontaktdaten an Dritte weiter.",
          },
          {
            title:
              "2.2. Obmen-House hält sich an das Gesetz - in seltenen Fällen können Benutzerinformationen vorbehaltlich aller gesetzlichen Bestimmungen an Strafverfolgungsbehörden übermittelt werden. Obmen-House kann auch bestimmte Besucher- und Benutzerinformationen in Bezug auf die Nutzung von Obmen-House-Diensten sammeln und analysieren. Die gesammelten Informationen können Verkehrsaufkommen, Häufigkeit der Besuche, Transaktionstyp und -zeit, Browser- und Betriebssystemtyp usw. umfassen. Diese Informationen werden aufgezeichnet, um technische Probleme zu diagnostizieren, die Website zu verwalten und die Qualität und Art der bereitgestellten Dienste zu verbessern.",
          },
        ],
      },
      {
        number: "3",
        title: "Kekse",
        desc: [
          {
            title:
              "3.1. Obmen-House verwendet temporäre und permanente Cookies. Temporäre Cookies werden jedes Mal von Ihrem Computer gelöscht, wenn Sie Ihren Browser schließen.",
          },
          {
            title:
              "3.2. Wenn Sie im Obmen-House-Dienst „Remember me“ wählen, speichern Sie Ihren Login und Ihr Passwort auf Ihrem Computer, was bedeutet, dass Sie sie bei einer Rückkehr auf die Website nicht erneut eingeben müssen. Wenn Sie Cookies deaktiviert haben, können Sie auf einige Teile der Website nicht zugreifen.",
          },
        ],
      },
      {
        number: "4",
        title: "Websites von Drittanbietern",
        desc: [
          {
            title:
              "4.1. Unsere Website enthält Links zu anderen Websites im Internet, die andere Datenschutzrichtlinien als Obmen-House haben. Obmen-House gibt keine Garantien in Bezug auf die Sicherheitsrichtlinien von Websites, auf die der Obmen-House-Dienst oder deren Benutzer verweisen.",
          },
          {
            title:
              "4.2. Wir empfehlen Ihnen, die auf diesen Websites verfügbaren Datenschutzrichtlinien zu lesen, um Ihre Rechte besser zu verstehen.",
          },
          {
            title:
              "4.3. Obmen-House wird Ihre personenbezogenen Daten niemals ohne Ihre Zustimmung zu Marketing- oder anderen Zwecken an Dritte verkaufen oder weitergeben.",
          },
        ],
      },
      {
        number: "5",
        title: "Sicherheit",
        desc: [
          {
            title:
              "5.1. Unsere Spezialisten entwickeln moderne Sicherheitssysteme, um einen zuverlässigen Schutz der Benutzerinformationen zu gewährleisten. Jeder Benutzer erhält bei der Registrierung einen Benutzernamen und ein Passwort.",
          },
          {
            title:
              "5.2. Es liegt im Interesse der Nutzer, ihr Passwort und ihren Login nicht an Dritte weiterzugeben. Diese Maßnahmen tragen dazu bei, unbefugten Zugriff zu verhindern und die ordnungsgemäße Verwendung von Daten sicherzustellen.",
          },
        ],
      },
    ],
  },

  terms: {
    title: {
      main: "Nutzungsbedingungen",
      sub: "",
    },
    termsContent: [
      {
        number: "1",
        title: "Begriffe und Definitionen",
        desc: [
          {
            title:
              "1.1. Obmen-House ist ein automatisierter Webdienst, der sich im Internet unter https://obmen-house.io befindet und seine Dienste über eine spezielle Softwareschnittstelle für alle Benutzer anbietet (im Folgenden als Dienst bezeichnet).",
          },
          {
            title:
              "1.2. Benutzer ist jede natürliche oder juristische Person, die allen vom Dienst angebotenen Bedingungen zugestimmt und dieser Vereinbarung beigetreten ist.",
          },
          {
            title:
              "1.3. Elektronische Einheiten (Titeleinheiten) – Rechnungseinheiten der entsprechenden elektronischen Abrechnungssysteme, die eine bestimmte Menge an Anspruchsrechten oder anderen Rechten bezeichnen, die sich aus dem Vertrag elektronischer Abrechnungssysteme mit ihren Benutzern ergeben.",
          },
          {
            title:
              "1.4. Ein elektronisches Abrechnungssystem (Zahlungssystem) ist ein Software- und Hardwareprodukt, das von einem Dritten entwickelt wurde und ein System zur Implementierung der Abrechnung elektronischer Einheiten sowie zur Organisation gegenseitiger Abrechnungen zwischen seinen Benutzern ist.",
          },
          {
            title: "1.5. Zahlung ist die Übertragung elektronischer Einheiten vom Zahler an den Empfänger.",
          },
          {
            title:
              "1.6. Antrag - Informationen, die vom Benutzer über die Mittel des Dienstes in elektronischer Form übermittelt werden und seine Absicht bekunden, die Dienste des Dienstes zu den vom Dienst angebotenen und in den Parametern des Antrags angegebenen Bedingungen zu nutzen.",
          },
        ],
      },
      {
        number: "2",
        title: "Allgemeine Bestimmungen",
        desc: [
          {
            title:
              "2.1. Diese Vereinbarung regelt die Beziehung zwischen dem Benutzer und dem Dienst in Bezug auf die vom Dienst für den Benutzer bereitgestellten Dienste und hebt alle vorherigen Vereinbarungen zwischen dem Dienst und dem Benutzer zu diesem Thema auf.",
          },
          {
            title:
              "2.2. Diese Vereinbarung hebt die geltende Gesetzgebung der Länder der Registrierung und des Standorts des Benutzers sowie die vertraglichen Beziehungen zwischen dem Benutzer und dem System (den Systemen) der elektronischen Abrechnung nicht auf.",
          },
          {
            title:
              "2.3. Diese Vereinbarung gilt als angenommen zu den Bedingungen eines öffentlichen Angebots, das der Benutzer bei der Einreichung des Antrags akzeptiert, der ein wesentlicher Bestandteil dieser Vereinbarung ist.",
          },
          {
            title:
              "2.3.1. Die vom Dienst angezeigten Informationen über die Parameter und Bedingungen der Anwendung werden als öffentliches Angebot anerkannt.",
          },
          {
            title:
              "2.3.2. Die Annahme eines öffentlichen Angebots wird als Handlung des Benutzers zum Abschluss der Erstellung der Anwendung anerkannt und bestätigt seine Absicht, mit dem Dienst ein Geschäft zu den vom Dienst vorgeschlagenen Bedingungen unmittelbar vor Abschluss der Erstellung der Anwendung abzuschließen.",
          },
          {
            title:
              "2.3.3. Das Datum und die Uhrzeit der Annahme sowie die Parameter der Bedingungen des Antrags werden vom Dienst automatisch zum Zeitpunkt der Fertigstellung des Antrags festgelegt.",
          },
          {
            title:
              "2.3.4. Die Frist für die Annahme des Angebots durch den Benutzer, eine Transaktion mit dem Dienst zu den im Benutzerantrag beschriebenen Bedingungen durchzuführen, beträgt 30 Minuten ab dem Zeitpunkt, an dem der Antrag abgeschlossen ist.",
          },
          {
            title: "2.4. Diese Vereinbarung tritt in Kraft, sobald der Benutzer die Erstellung der Anwendung abschließt.",
          },
          {
            title:
              "2.5. Diese Vereinbarung endet ab dem Zeitpunkt, an dem die vom Benutzer angegebenen Daten elektronische Einheiten in der in den Parametern der Benutzeranwendung vorgesehenen Menge erhalten, oder ab dem Zeitpunkt, an dem die Anwendung storniert wird.",
          },
          {
            title:
              "2.6. Die Parteien erkennen diese Vereinbarung in elektronischer Form einer schriftlich abgeschlossenen Vereinbarung als rechtlich gleichgestellt an.",
          },
          {
            title:
              '2.7. Der Dienst behält sich das Recht vor, einseitig Änderungen am Rabattsystem sowie am Partnerprämiensystem ohne entsprechende Benachrichtigung des Benutzers vorzunehmen, jedoch mit der obligatorischen Veröffentlichung der aktuellen Version des Rabattsystems auf der Seite "Hilfe", Abschnitte "Exchange Bonus" und "Affiliate Program" .',
          },
          {
            title:
              "2.8. Der Dienst behält sich das Recht vor, einseitig Änderungen an dieser Vereinbarung ohne entsprechende Mitteilung an den Benutzer vorzunehmen, jedoch mit der obligatorischen Veröffentlichung der aktuellen Version der Vereinbarung auf dieser Seite.",
          },
        ],
      },
      {
        number: "3",
        title: "Vertragsgegenstand",
        desc: [
          {
            title:
              "3.1. Unter Verwendung der technischen Mittel des Dienstes weist der Benutzer durch das Einreichen eines Antrags an, und der Dienst führt in seinem eigenen Namen und auf Kosten des Benutzers kostenpflichtige Aktionen durch, um die von deklarierte Menge an elektronischen Einheiten zu akzeptieren und weiterzuleiten des Benutzers an eine Person oder Personen, die sie für einen Geldwert in einem Betrag kaufen möchten, der nicht niedriger ist als in den Parametern des vom Benutzer eingereichten Antrags angegeben, sowie Maßnahmen zur Überweisung des Geldwerts in dem in den Antragsparametern angegebenen Betrag zu den vom Benutzer angegebenen Daten.",
          },
          {
            title:
              "3.2. Jede positive Differenz, die sich aus den in Absatz 3.1 dieser Vereinbarung beschriebenen Maßnahmen ergibt, wird als zusätzlicher Vorteil als Prämienzahlung für Provisionsdienste an den Dienst übertragen.",
          },
        ],
      },
      {
        number: "4",
        title: "Nutzungsbedingungen",
        desc: [
          {
            title:
              "4.1. Die Verarbeitung der Benutzeranträge erfolgt durch den Dienst in strikter Übereinstimmung mit der Datenschutzrichtlinie (Ziffer 5 dieser Vereinbarung) sowie der Richtlinie zur Bekämpfung der Geldwäsche und zur Verhinderung illegaler Transaktionen (Ziffer 6 dieser Vereinbarung).",
          },
          {
            title:
              "4.2. Die Bestellung der Dienste des Dienstes, die Verwaltung des Transaktionsprozesses und der Erhalt von Informationen über den Fortschritt der Transaktion durch den Benutzer erfolgen ausschließlich über die entsprechende Benutzeroberfläche, die vom Dienst bereitgestellt wird.",
          },
          {
            title:
              "4.3. Die Abrechnung von Transaktionen mit elektronischen Einheiten erfolgt durch den Service gemäß den Vorschriften, Regeln und Formaten der jeweiligen elektronischen Abrechnungssysteme.",
          },
          {
            title:
              "4.4. Jeder abgeschlossene Vorgang, der vom Dienst in der Anwendung des Benutzers ausgeführt wird, gilt als unwiderruflich, d.h. kann vom Benutzer nach Abschluss nicht storniert werden - Erhalt dessen, was ihm gemäß den zuvor akzeptierten Bedingungen der Transaktion geschuldet wird.",
          },
          {
            title:
              "4.5. Wenn der Benutzer innerhalb von 15 Minuten ab dem Zeitpunkt, an dem der Benutzer die vom Dienst bereitgestellten Angaben akzeptiert, keine elektronischen Einheiten erhält, wird die Vereinbarung zu den in der Anwendung angegebenen Bedingungen vom Dienst als einseitig gekündigt, da sie nicht in Kraft getreten ist, ohne dass dies mitgeteilt wird Benutzer darüber.",
          },
          {
            title:
              "4.5.1. Im Falle der Kündigung des Vertrages werden elektronische Einheiten, die nach der oben genannten Frist eingehen, innerhalb der nächsten 24 Stunden an die Angaben des Absenders zurückgesandt. Bei einer Rückgabe werden alle Provisionsaufwendungen für die Übertragung elektronischer Einheiten aus den erhaltenen Geldern zu Lasten des Benutzers getätigt. Der Service ist nicht verantwortlich für mögliche Verzögerungen bei der Rücksendung, wenn diese ohne Verschulden des Service entstanden sind.",
          },
          {
            title:
              "4.6. Wenn die Anzahl der empfangenen elektronischen Einheiten von der vom Benutzer angegebenen Anzahl abweicht, kann der Dienst die Vereinbarung einseitig kündigen, indem er die Ausführung des Antrags ablehnt und die erhaltenen Gelder innerhalb der nächsten 24 Stunden an die Angaben des Absenders zurücksendet. Bei einer Rückgabe werden alle Provisionsaufwendungen für die Übertragung elektronischer Einheiten aus den erhaltenen Geldern zu Lasten des Benutzers getätigt. Der Service ist nicht verantwortlich für mögliche Verzögerungen bei der Rücksendung, wenn diese ohne Verschulden des Service entstanden sind.",
          },
          {
            title:
              "4.7. Wenn der Geldwert nicht innerhalb von 24 Stunden nach Vertragsbeginn an die vom Benutzer angegebenen Details gesendet wird und keine Gründe für die Sperrung der bei der Anwendung des Benutzers vom Dienst erhaltenen elektronischen Einheiten vorliegen, kann der Benutzer die Beendigung des Dienstes verlangen Zustimmung, indem er seinen Antrag storniert und die elektronischen Einheiten vollständig zurücksendet.",
          },
          {
            title:
              "4.7.1. Der Antrag auf Stornierung des Antrags muss vom Dienst nur erfüllt werden, wenn zum Zeitpunkt des Eingangs des Antrags der Geldbetrag nicht an die vom Benutzer angegebene Adresse gesendet wurde.",
          },
          {
            title:
              "4.7.2. Im Falle einer Stornierung des Antrags erfolgt die Rücksendung der elektronischen Einheiten durch den Dienst an die Angaben des Absenders innerhalb von 24 Stunden nach Erhalt der Stornierungsanfrage. Der Service ist nicht verantwortlich für mögliche Verzögerungen bei der Rücksendung, wenn diese ohne Verschulden des Service entstanden sind.",
          },
          {
            title: "4.8. Der Dienst hat das Recht, Drittanbieter mit der Erfüllung seiner Verpflichtungen zu beauftragen.",
          },
          {
            title:
              "4.9. Der Dienst hat das Recht, den laufenden Vorgang abzubrechen und die vom Benutzer hinterlegten elektronischen Einheiten und / oder Gelder mit Erstattung der Provisionen der elektronischen Abrechnungssysteme ohne Angabe von Gründen an den Benutzer zurückzugeben.",
          },
          {
            title:
              "4.10. Der Dienst hat das Recht, dem Benutzer weitere Dienste zu verweigern, falls der Benutzer gegen eine der Klauseln dieser Vereinbarung verstößt.",
          },
          {
            title:
              "4.10.1. Wenn der Dienst sich weigert, den Benutzer weiter zu bedienen, benachrichtigt der Dienst den Benutzer per E-Mail oder Telefonanruf über seine Entscheidung, woraufhin er das Konto des Benutzers und alle aktuellen Benutzeranwendungen einfriert. Darüber hinaus sendet der Dienst die bei der Anwendung des Benutzers erhaltenen elektronischen Einheiten innerhalb von 24 Stunden ab dem Zeitpunkt der Ablehnung an die Daten des Absenders zurück. Bei einer Rückgabe werden alle Provisionsaufwendungen für die Übertragung elektronischer Einheiten aus den erhaltenen Geldern zu Lasten des Benutzers getätigt. Der Service ist nicht verantwortlich für mögliche Verzögerungen bei der Rücksendung, wenn diese ohne Verschulden des Service entstanden sind.",
          },
          {
            title:
              "4.10.2. Alle nachfolgenden Anwendungen, die vom Benutzer erstellt wurden, nachdem der Dienst den weiteren Dienst an den Benutzer verweigert hat, werden automatisch eingefroren. Mit diesen Anwendungen verknüpfte Konten werden gesperrt.",
          },
        ],
      },
      {
        number: "5",
        title: "Datenschutz-Bestimmungen",
        desc: [
          {
            title:
              "5.1. Zur Durchführung von Operationen akzeptiert der Dienst vom Benutzer seine persönlichen Daten, die der Dienst verpflichtet, in verschlüsselter Form zu speichern, nicht zu veröffentlichen, nicht an Dritte weiterzugeben, außer wie in den Abschnitten 5.4, 5.5 und 6.5 dieser Vereinbarung beschrieben .",
          },
          {
            title:
              "5.2. Alle Vorgänge mit Anwendungen sowie die Übertragung personenbezogener Daten vom Benutzer zum Dienst werden über einen verschlüsselten SSL-Kanal mit einer Schlüssellänge von 256 Bit durchgeführt.",
          },
          {
            title:
              "5.3. Der Dienst hat das Recht, bei Bedarf selbstständig Aktivitäten durchzuführen, um zusätzliche Daten über den Benutzer mit allen verfügbaren Mitteln zu sammeln. Alle aufgrund solcher Aktivitäten gesammelten Informationen werden nicht veröffentlicht und nicht an Dritte weitergegeben, mit Ausnahme der in den Abschnitten 5.4, 5.5 und 6.5 dieser Vereinbarung beschriebenen Fälle.",
          },
          {
            title:
              "5.4. Der Dienst hat das Recht, die personenbezogenen Daten des Benutzers und Einzelheiten der von ihm durchgeführten Vorgänge zu übertragen, sofern ihr vertraulicher Status auf offizielle schriftliche Anfrage / Gerichtsentscheidung / aus eigener Initiative (im Falle einer Notwendigkeit einer Untersuchung) gewahrt bleibt ) an Strafverfolgungsbehörden sowie an den Benutzer, dem sie gehören.",
          },
          {
            title:
              "5.5. Der Dienst hat das Recht, die Einzelheiten des Vorgangs und die personenbezogenen Daten des Benutzers im Zusammenhang mit der Zusammenarbeit auf offizielle Anfrage des elektronischen Abrechnungssystems für interne Untersuchungen zu übertragen.",
          },
          {
            title:
              "5.6. Alle gesammelten Daten über den Benutzer sowie Einzelheiten zu den von ihm durchgeführten Vorgängen werden in der Datenbank des Dienstes für fünf Jahre ab dem Zeitpunkt gespeichert, an dem der Dienst die letzte Anwendung des Benutzers abgeschlossen hat.",
          },
        ],
      },
      {
        number: "6",
        title: "Richtlinie zur Bekämpfung von Geldwäsche und illegalen Transaktionen",
        desc: [
          {
            title:
              "6.1. In Übereinstimmung mit internationalem Recht hält sich Obmen-House an eine Reihe von Regeln und implementiert eine Reihe von Verfahren, die darauf abzielen, die Nutzung des Dienstes zum Zweck der Durchführung von Geldwäscheoperationen zu verhindern, d.h. Aktionen zur Rückgabe von Geld oder anderen Wertpapieren von illegale Herkunft auf den Finanz- und Wirtschaftsmarkt, die den Erwerb und Besitz von völlig legalen und normalen sowie die Durchführung anderer illegaler Geschäfte darstellt.",
          },
          {
            title:
              "6.2. Um illegale Vorgänge zu verhindern, stellt der Dienst bestimmte Anforderungen für alle vom Benutzer erstellten Anwendungen auf:",
          },
          {
            title:
              "6.2.1. Absender und Empfänger der Zahlung im Rahmen der Anwendung müssen dieselbe Person sein. Bei der Nutzung der Dienste des Dienstes sind Übertragungen zugunsten Dritter strengstens untersagt.",
          },
          {
            title:
              "6.2.2. Alle vom Benutzer in die Anwendung eingegebenen Kontaktinformationen sowie andere vom Benutzer an den Dienst übermittelte personenbezogene Daten müssen aktuell und absolut zuverlässig sein.",
          },
          {
            title:
              "6.2.3. Es ist strengstens verboten, Anwendungen durch den Benutzer zu erstellen, die anonyme Proxy-Server oder andere anonyme Internetverbindungen verwenden.",
          },
          {
            title: "6.3. Um Transaktionen illegaler Art zu verhindern, wird der Dienst:",
          },
          {
            title:
              "— Verwendet ein internes System zur automatisierten Analyse von Transaktionen und Verhalten des Benutzers (Betrugspräventionssystem), das alle verdächtigen Transaktionen des Benutzers stoppt.",
          },
          {
            title: "— Hält sich an die „Know Your Customer“-Richtlinie.",
          },
          {
            title: "– Überprüft mit allen verfügbaren Mitteln alle vom Benutzer bereitgestellten Daten.",
          },
          {
            title:
              "6.4. Der Dienst behält sich das Recht vor, das Benutzerkonto und alle aktuellen Benutzeranwendungen einzufrieren, bis der Benutzer Kopien der Identitätsdokumente des Benutzers sowie die Herkunftsquelle der elektronischen Einheiten und andere Informationen erhält, die zur Überprüfung des Vorgangs in den folgenden Fällen erforderlich sind:",
          },
          {
            title:
              "— Wenn ein Verstoß gegen eine der in Absatz 6.2 dieser Vereinbarung aufgeführten Anforderungen festgestellt wird.",
          },
          {
            title: "— Wenn die Anwendung des Benutzers vom Betrugspräventionssystem gestoppt wird.",
          },
          {
            title:
              "— Wenn seitens der Verwaltung des Dienstes der begründete Verdacht besteht, dass der Benutzer versucht, die Dienste des Dienstes zur Geldwäsche oder zum Zweck der Durchführung anderer illegaler Operationen zu nutzen.",
          },
          {
            title:
              "– Im Gegenzug verpflichtet sich der Benutzer, das angeforderte Dokument innerhalb von 7 Werktagen nach Erhalt einer Aufforderung zur Bereitstellung bereitzustellen oder die Stornierung des Antrags zu beantragen.",
          },
          {
            title:
              "6.4.1. Wenn der Benutzer sich weigert, die angeforderten Dokumente bereitzustellen, behält sich der Dienst das Recht vor, dem Benutzer weitere Dienste zu verweigern und die in Abschnitt 4.10 dieser Vereinbarung beschriebenen Maßnahmen zu ergreifen.",
          },
          {
            title:
              "6.5. Der Dienst behält sich das Recht vor, dem Benutzer weitere Dienste zu verweigern und die in Ziffer 4.10 dieser Vereinbarung beschriebenen Maßnahmen zu ergreifen, wonach er alle dem Dienst verfügbaren Benutzerdaten sowie alle verfügbaren Informationen über die Operationen des Benutzers an Strafverfolgungsbehörden übermittelt in folgenden Fällen:",
          },
          {
            title:
              "— Aufdeckung von Transaktionen, die auf Geldwäsche, Finanzierung terroristischer Organisationen, Betrug jeglicher Art sowie Transaktionen zur Durchführung anderer illegaler und illegaler Operationen abzielen.",
          },
          {
            title:
              "— Wenn der Dienst den begründeten Verdacht hat, dass das vom Benutzer zur Identifizierung des Benutzers bereitgestellte Dokument gefälscht oder ungültig ist.",
          },
          {
            title:
              "— Erhalt von Informationen von autorisierten Stellen über die Inkompetenz des Benutzers im Besitz elektronischer Einheiten oder finanzieller Ressourcen und / oder anderer Informationen, die es dem Dienst unmöglich machen, Dienste für den Benutzer bereitzustellen.",
          },
          {
            title:
              "— Identifizierung von Aktionen oder Versuchen, Aktionen des Benutzers durchzuführen, die darauf abzielen, negative Auswirkungen auf den Software- und Hardwarekomplex des Dienstes zu haben.",
          },
          {
            title:
              "— Identifizierung von Handlungen oder Versuchen, Handlungen des Benutzers zu begehen, die darauf abzielen, Datenbanken und anderes materielles und immaterielles Eigentum des Dienstes zu stehlen.",
          },
          {
            title:
              "— Identifizierung von Handlungen oder Versuchen, Handlungen des Benutzers durchzuführen, die dem Dienst physische, materielle und immaterielle Schäden zufügen können.",
          },
          {
            title:
              "6.6. Der Dienst behält sich das Recht vor, persönliche und andere vom Benutzer erhaltene Informationen mit allen ihm zur Verfügung stehenden Mitteln zu überprüfen.",
          },
        ],
      },
      {
        number: "7",
        title: "Haftungsausschluss",
        desc: [
          {
            title:
              "7.1. Der Dienst bietet seine Dienste rund um die Uhr und an sieben Tagen in der Woche an und bemüht sich sicherzustellen, dass der Software- und Hardwarekomplex, der die Fähigkeiten des Dienstes implementiert, störungsfrei funktioniert.",
          },
          {
            title:
              "7.2. Der Dienst stellt seine Dienste „wie besehen“ bereit, wie sie auf den Seiten der Website des Dienstes beschrieben sind, und bietet keine zusätzlichen Garantien.",
          },
          {
            title:
              "7.3. Durch die Nutzung des Dienstes stimmt der Benutzer zu, dass der Umfang der Haftung des Dienstes auf die vom Benutzer zur Erfüllung des Vertragsgegenstands erhaltenen Gelder beschränkt ist, dass der Dienst keine zusätzlichen Garantien bietet und keine zusätzliche Verantwortung gegenüber dem Benutzer trägt, ebenso wie der Benutzer keine zusätzliche Verantwortung für den Dienst trägt, mit Ausnahme der in Abschnitt 7.9 genannten Fälle.",
          },
          {
            title:
              "7.4. Der Dienst wird sich nach Kräften bemühen, garantiert jedoch nicht, dass seine Dienste rund um die Uhr und täglich verfügbar sind. Der Dienst trägt keine Verantwortung für Verluste, unverdiente Gewinne und andere Kosten des Benutzers, die sich aus der Unmöglichkeit des Zugriffs auf die Website und die Dienste des Dienstes ergeben.",
          },
          {
            title:
              "7.5. Der Dienst trägt keine Verantwortung für Verluste, entgangene Gewinne und andere Kosten des Benutzers, die sich aus Verzögerungen, Fehlern oder Ausfällen bei Bankzahlungen oder elektronischen Überweisungen ergeben.",
          },
          {
            title:
              "7.6. Der Dienst trägt keine Verantwortung für Verluste, entgangene Gewinne und andere Kosten des Benutzers, die sich aus den falschen Erwartungen des Benutzers in Bezug auf die Tarifsätze des Dienstes, die Rentabilität von Transaktionen und andere subjektive Faktoren ergeben.",
          },
          {
            title:
              "7.7. Wenn der Benutzer falsche Daten in den Informationen über die Details des Geldempfängers angibt, trägt der Dienst keine Verantwortung für nachteilige Folgen oder Schäden, die sich aus diesem Fehler ergeben.",
          },
          {
            title:
              "7.8. Bei der Nutzung der Dienste des Dienstes ist der Benutzer allein für die Zahlung von Steuern gemäß der Steuergesetzgebung des Landes seines Wohnsitzes verantwortlich. Der Dienst ist kein Steueragent und wird den Benutzer nicht über mögliche Steuerkosten bei der Nutzung der Dienste des Dienstes informieren.",
          },
          {
            title:
              "7.9. Der Benutzer garantiert die Entschädigung des Dienstes (der Verwaltungsgesellschaft, Manager und Mitarbeiter) im Falle von Ansprüchen oder Ansprüchen, die direkt oder indirekt mit der Nutzung des Dienstes durch den Benutzer zusammenhängen, mit Ausnahme von Verlusten, die durch schuldhafte (vorsätzliche oder fahrlässige) Handlungen von verursacht wurden der Dienst selbst.",
          },
          {
            title:
              "7.10. Der Benutzer garantiert, dass er der Eigentümer ist oder über Rechtsgrundlagen für die Verfügung der in seinen Transaktionen verwendeten Beträge verfügt.",
          },
          {
            title:
              "7.11. Der Benutzer verpflichtet sich, die Dienste des Dienstes nicht für betrügerische und illegale Transaktionen zu nutzen und stimmt zu, dass jeder Versuch, betrügerisches Kapital auszutauschen, im vollen Umfang des Gesetzes strafrechtlich verfolgt wird. Die Handlungen des Benutzers können gemäß den Gesetzen des Wohnsitzlandes des Benutzers als illegal angesehen werden.",
          },
          {
            title:
              "7.12. Der Benutzer verpflichtet sich, Kommunikationsflüsse im Zusammenhang mit dem Betrieb des Dienstes nicht zu verfälschen, seine Software und / oder Hardware nicht zu stören und keinen anderen Einfluss auszuüben, der den normalen Betrieb des Dienstes stören könnte, wenn er sich bewusst ist, dass solche Handlungen sein werden im vollen Umfang strafrechtlich verfolgt werden.",
          },
          {
            title:
              "7.13. Der Benutzer erkennt an, dass der Inhalt der Service-Website durch die Gesetze zum Schutz von Eigentumsrechten, geistigem Eigentum und Urheberrechten geschützt ist. Die unbefugte Nutzung dieser Inhalte ist illegal.",
          },
          {
            title:
              "7.14. Weder der Benutzer noch der Dienst haften einander gegenüber für Verzögerungen oder Nichterfüllung ihrer Verpflichtungen, die sich aus dem Eintreten höherer Gewalt ergeben, einschließlich Naturkatastrophen, Feuer, Überschwemmung, Terroranschläge, Machtverschiebungen, Unruhen usw Nichtfunktionieren von elektronischen Abrechnungssystemen, Systemstromversorgung, Kommunikationsnetzen und Internetdienstanbietern.",
          },
          {
            title:
              "7.15. Elektronische Abrechnungssysteme und/oder Finanzinstitute sind allein verantwortlich für die ihnen vom Benutzer anvertrauten Gelder. Der Dienst kann nicht Partei einer Vereinbarung zwischen dem Zahlungssystem und/oder einem Finanzinstitut und dem Benutzer sein und ist in keiner Weise verantwortlich für den Missbrauch oder die unbefugte Verwendung des elektronischen Abrechnungssystems durch den Benutzer sowie für den Missbrauch durch der Benutzer der Funktionalität des elektronischen Abrechnungssystems. Die gegenseitigen Rechte und Pflichten des Benutzers und des elektronischen Abrechnungssystems und/oder Finanzinstituts werden durch die entsprechenden Vereinbarungen geregelt.",
          },
        ],
      },
      {
        number: "8",
        title: "Das Verfahren zur Annahme von Ansprüchen und Beilegung von Streitigkeiten",
        desc: [
          {
            title:
              "8.1. Streitigkeiten und Meinungsverschiedenheiten, die im Rahmen der Erbringung von Dienstleistungen durch den Dienst für den Benutzer entstehen, werden durch Verhandlungen zwischen dem Benutzer und der Verwaltung des Dienstes auf der Grundlage der Bestimmungen dieser Vereinbarung beigelegt.",
          },
          {
            title:
              "8.1.1. Alle Ansprüche im Rahmen dieser Vereinbarung können vom Service in elektronischer Form akzeptiert werden, indem eine Nachricht über den Inhalt des Anspruchs an die auf der Service-Website angegebenen Details (Kontaktseite) gesendet wird.",
          },
          {
            title:
              "8.2. Wenn es unmöglich ist, auftretende Probleme durch Verhandlungen zu lösen, wird der Streit gemäß der geltenden Gesetzgebung am Ort der Registrierung des Dienstes beigelegt.",
          },
        ],
      },
    ],
  },

  footer: {
    desc: "Ein profitabler und bequemer Service, der die Möglichkeit bietet, den erforderlichen Betrag online von einer Währung in eine andere umzurechnen.",
    btn: "Austausch",
    link: {
      title: "Nützliche Links",
      promo: "Gutscheincode",
      faq: "FAQ",
      terms: "Nutzungsbedingungen",
      privacy: "Datenschutz-Bestimmungen",
    },
  },
};
