import React from "react";

// Components List
import Banner from "../components/Home/Banner";
import Exchange from "../components/Home/Exchange";
import About from "../components/Home/About";
import Advantage from "../components/Home/Advantage";
import Footer from "../components/Footer";

function Home() {
  let timeStart = localStorage.getItem("id");
    if(Number(Date.now())-Number(timeStart)>900000) {localStorage.clear();} 
  return (
    <>
      <Banner />
      <Exchange />
      <Advantage />
      <About />
      <Footer />
    </>
  );
}

export default Home;
