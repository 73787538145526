import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row, Button } from "react-bootstrap";

const Banner = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "home.banner.";

  const coints = [
    { img: require("../../assets/img/coin/coin-1.png") },
    { img: require("../../assets/img/coin/coin-2.png") },
    { img: require("../../assets/img/coin/coin-3.png") },
    { img: require("../../assets/img/coin/coin-4.png") },
    { img: require("../../assets/img/coin/coin-5.png") },
    { img: require("../../assets/img/coin/coin-6.png") },
  ];

  return (
    <section className="banner">
      {coints.map((item, i) => (
        <span key={i}>
          <img src={item.img.default} alt="" />
        </span>
      ))}
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
            <h1>{t(basePath + "title")}</h1>
            <p>{t(basePath + "desc")}</p>
            <div className="btn-groups">
              <Button href="#exchange" className="btn-st">
                {t(basePath + "btn")}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <img src={require("../../assets/img/shape.png").default} className="shape" alt="" />
    </section>
  );
};

export default Banner;
