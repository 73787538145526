import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { IconClipboardCheck } from "../../../utils/Icons";

const ModalSuccess = (props) => {
  // Translation
  const { t } = useTranslation();

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <IconClipboardCheck width="65px" height="65px" fill="#25ca7f" />
        <p className="mt-30">{t("home.exchenge.modalSuccess.title")}</p>
      </Modal.Body>
    </Modal>
  );
};

export default ModalSuccess;
