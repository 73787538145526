import React, { useContext, useEffect, useState } from "react";
import { getCurs } from "../../utils/Api";
import { useTranslation } from "react-i18next";
import { ExchangeContext } from "../../contexts/ExchangeContext";
import { Button, Col, Container, FormControl, InputGroup, Row } from "react-bootstrap";

// Components List
import FirstCurrency from "./element/FirstCurrency";
import SecondCurrency from "./element/SecondCurrency";
import { Link } from "react-router-dom";
import { IconImport, IconUpload } from "../../utils/Icons";
import Feature from "./element/Feature";
import ModalError from "./element/ModalError";
import ModalSend from "./element/ModalSend";

const Exchange = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "home.exchenge.";

  const {
    setFirstCurrencyInner,
    setSecondCurrencyInner,
    currency,
    cryptoCurrency,
    currencyAll,
    firstCurrency,
    setFirstCurrency,
    secondCurrency,
    setSecondCurrency,
    resalt,
    setResalt,
    amount,
    setAmount,
    setToWallet
  } = useContext(ExchangeContext);

  const [modalErrorShow, setModalErrorShow] = useState(false);
  const [modalSendShow, setModalSendShow] = useState(false);

  const chousePair = (e) => {
    if (e.target.parentNode.id === "first_select") {
      setFirstCurrency(e.target.id);
      setFirstCurrencyInner(e.target.dataset.value);
      setToWallet(e.target.dataset.value)
    } else if (e.target.parentNode.id === "second_select") {
      setSecondCurrency(e.target.id);
      setSecondCurrencyInner(e.target.dataset.value);
    }
  };

  const handleCheck = (e) => {
    if (e.target.value.match(/[^0-9]/g)) {
      e.target.value = e.target.value.replace(/[^0-9,.]/g, "");
    }
    setAmount(e.target.value);
  };

  const handleCheckForm = () => {
    if (firstCurrency === null || secondCurrency === null || amount === null || amount === 0 || firstCurrency === secondCurrency) {
      setModalErrorShow(true);
    } else {
      setModalSendShow(true);
    }
  };

  useEffect(() => {
    if (firstCurrency === secondCurrency) {
      setResalt(document.querySelector(".amount").value / document.querySelector(".amount").value);
      return null;
    } else if (firstCurrency && secondCurrency && firstCurrency !== "Выберете валюту" && secondCurrency !== "Выберете валюту") {
      //Crypto Currency - Crypto Currency
      for (let i = 0; i < cryptoCurrency.length; i++) {
        for (let k = 0; k < cryptoCurrency.length; k++) {
          if (cryptoCurrency[i] === firstCurrency && cryptoCurrency[k] === secondCurrency) {
            console.log(1);
            getCurs(firstCurrency, "USDT")
              .then((res) => {
                let price = res.price;
                getCurs(secondCurrency, "USDT").then((res) => {
                  setResalt(price / res.price);
                });
              })
              .catch((e) => alert("1"));
            return null;
          }
        }
      }
      //Crypto Currency - Currency
      for (let i = 0; i < cryptoCurrency.length; i++) {
        for (let k = 0; k < currency.length; k++) {
          if (cryptoCurrency[i] === firstCurrency && currency[k] === secondCurrency) {
            if (firstCurrency==="ETC" && secondCurrency === "RUB") {
              getCurs(firstCurrency, "USDT")
                .then((res) => {
                  let price = res.price;
                  getCurs("USDT", "RUB").then((res) => {
                    setResalt(price * res.price);
                  });
                  return null;
                })
                .catch((e) => alert("2.1"));
              return null;
            }
            if (firstCurrency==="TRX" && secondCurrency === "RUB") {
              getCurs(firstCurrency, "USDT")
                .then((res) => {
                  let price = res.price;
                  getCurs("USDT", "RUB").then((res) => {
                    setResalt(price * res.price);
                  });
                  return null;
                })
                .catch((e) => alert("2.1"));
              return null;
            }
            if (firstCurrency==="ETC" && secondCurrency === "TRY") {
              getCurs(firstCurrency, "USDT")
                .then((res) => {
                  let price = res.price;
                  getCurs("USDT", "TRY").then((res) => {
                    setResalt(price * res.price);
                  });
                  return null;
                })
                .catch((e) => alert("2.1"));
              return null;
            }
            if (secondCurrency !== "UAH") {
              getCurs(firstCurrency, secondCurrency)
                .then((res) => {
                  setResalt(res.price);
                })
                .catch((e) => alert("2"));
              return null;
            } else if (secondCurrency === "UAH") {
              getCurs(firstCurrency, "USDT")
                .then((res) => {
                  let price = res.price;
                  getCurs("USDT", "UAH").then((res) => {
                    setResalt(price * res.price);
                  });
                  return null;
                })
                .catch((e) => alert("2.1"));
              return null;
            } 
          }
        }
      }
      //Currency - Currency
      for (let i = 0; i < currency.length; i++) {
        for (let k = 0; k < currency.length; k++) {
          if (firstCurrency === "USDT" && secondCurrency === "EUR") {
            getCurs(secondCurrency, firstCurrency)
              .then((res) => {
                setResalt(1 / res.price);
              })
              .catch((e) => alert("3.0"));
            return null;
          } else if (secondCurrency === "USDT" && (firstCurrency === "UAH" || firstCurrency === "RUB")) {
            getCurs(secondCurrency, firstCurrency)
              .then((res) => {
                setResalt(1 / res.price);
              })
              .catch((e) => alert("3.1"));
            return null;
          } else if (secondCurrency === "EUR" && (firstCurrency === "UAH" || firstCurrency === "RUB")) {
            getCurs(secondCurrency, "USDT")
              .then((res) => {
                let price = res.price;
                getCurs("USDT", firstCurrency).then((res) => {
                  setResalt((1 / res.price) * price);
                });
                return null;
              })
              .catch((e) => alert("3.2"));
            return null;
          } else if (firstCurrency === "USDT" && (secondCurrency === "UAH" || secondCurrency === "RUB")) {
            getCurs(firstCurrency, secondCurrency)
              .then((res) => {
                setResalt(res.price * 0.17 + Number(res.price));
              })
              .catch((e) => alert("3.3"));
            return null;
          } else if (firstCurrency === "EUR" && (secondCurrency === "UAH" || secondCurrency === "RUB")) {
            getCurs(firstCurrency, "USDT")
              .then((res) => {
                let price = res.price;
                getCurs("USDT", secondCurrency).then((res) => {
                  setResalt((price * res.price) / 1);
                });
                return null;
              })
              .catch((e) => alert("3.4"));
            return null;
          } else if (firstCurrency === "EUR" && secondCurrency === "USDT") {
            getCurs(firstCurrency, secondCurrency)
              .then((res) => {
                setResalt(res.price);
              })
              .catch((e) => alert("3.4"));
            return null;
          } else if (firstCurrency === "USDT" && secondCurrency === "TRY") {
            getCurs(firstCurrency, secondCurrency)
              .then((res) => {
                setResalt(res.price);
              })
              .catch((e) => alert("3.4"));
            return null;
          }
        }
      }
      //Currency - Crypto Currency
      for (let i = 0; i < cryptoCurrency.length; i++) {
        for (let k = 0; k < currency.length; k++) {
          if (cryptoCurrency[i] === secondCurrency && currency[k] === firstCurrency) {
            if (firstCurrency === "RUB" && secondCurrency === "TRX") {
              getCurs(secondCurrency, "USDT")
                .then((res) => {
                  let price = res.price;
                  getCurs("USDT", firstCurrency).then((res) => {
                    setResalt(1 / res.price / price);
                  });
                  return null;
                })
                .catch((e) => alert("4.1"));
              return null;
            }
            if (firstCurrency === "RUB" && (secondCurrency === "ETH" || secondCurrency === "ETC")) {
              getCurs(secondCurrency, "USDT")
                .then((res) => {
                  let price = res.price;
                  getCurs("USDT", firstCurrency).then((res) => {
                    setResalt(1 / res.price / price);
                  });
                  return null;
                })
                .catch((e) => alert("4.2"));
              return null;
            } 
            if(firstCurrency === "USDT" && secondCurrency === "TRX"){
              getCurs(secondCurrency, firstCurrency)
                .then((res) => {
                  setResalt(1 / res.price + (1 / res.price)*0.08);
                })
                .catch((e) => alert("4.3"));
              return null;
            }else {
              getCurs(secondCurrency, firstCurrency)
                .then((res) => {
                  setResalt(1 / res.price);
                })
                .catch((e) => alert("4.3"));
              return null;
            }
          }
        }
      }
    }
  }, [firstCurrency, secondCurrency]);
  return (
    <section id="exchange" className="exchange">
      <Container>
        <Row className="justify-content-center">
          <Col lg={10}>
            <div className="box">
              <img className="leaf" src={require("../../assets/img/leaf.png").default} alt="" />
              <h1>
                {t(basePath + "title.main")} <span>{t(basePath + "title.sub")}</span>
              </h1>
              <Row>
                <Col lg={6}>
                  <div className="form">
                    <h4>{t(basePath + "give")}</h4>
                    <InputGroup>
                      <InputGroup.Text id="inputGroupPrepend">
                        <IconImport width="18px" height="18px" fill="#000" />
                      </InputGroup.Text>
                      <FormControl
                        placeholder={t(basePath + "amount")}
                        type="number"
                        className="amount"
                        onChange={(e) => handleCheck(e)}
                        required
                      />

                      {/* Select Currency */}
                      <FirstCurrency currencyAll={currencyAll} chousePair={chousePair} firstCurrency={firstCurrency} />
                    </InputGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form">
                    <h4>{t(basePath + "receive")}</h4>
                    <InputGroup>
                      <InputGroup.Text id="inputGroupPrepend">
                        <IconUpload width="18px" height="18px" fill="#000" />
                      </InputGroup.Text>
                      <FormControl value={ (resalt*Number(amount)).toFixed(4) } readOnly />

                      {/* Select Currency */}
                      <SecondCurrency currencyAll={currencyAll} chousePair={chousePair} secondCurrency={secondCurrency} />
                    </InputGroup>
                  </div>
                </Col>
                <p>
                  {t(basePath + "agreement.first")} <Link to="/terms">{t(basePath + "agreement.link")}</Link>{" "}
                  {t(basePath + "agreement.second")}
                </p>
                <div className="btn-groups justify-content-center">
                  <Button className="btn-st" onClick={handleCheckForm}>
                    {t(basePath + "btn")}
                  </Button>
                </div>
              </Row>
            </div>
          </Col>
        </Row>

        {/* Feature Component */}
        <Feature />

        {/* Modal Error Component */}
        <ModalError show={modalErrorShow} onHide={() => setModalErrorShow(false)} />

        {/* Modal From Component */}
        <ModalSend show={modalSendShow} onHide={() => setModalSendShow(false)} setModalSendShow={setModalSendShow} />
      </Container>
      <svg className="wave-firts" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
        <path
          fill="#fff"
          fillOpacity="1"
          d="M0,64L120,53.3C240,43,480,21,720,21.3C960,21,1200,43,1320,53.3L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
        ></path>
      </svg>
      <svg className="wave-second" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
        <path
          fill="#000"
          fillOpacity="1"
          d="M0,64L120,53.3C240,43,480,21,720,21.3C960,21,1200,43,1320,53.3L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
        ></path>
      </svg>
    </section>
  );
};

export default Exchange;
