import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const About = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "home.about.";

  return (
    <section className="about">
      <Container>
        <Row className="align-items-center">
          <Col lg={6}>
            <img src={require("../../assets/img/backgrounds/about_bg.png").default} alt="" />
          </Col>
          <Col lg={6}>
            <div className="title">
              <span>{t(basePath + "title.small")}</span>
              <h1>
                {t(basePath + "title.main")} <b>{t(basePath + "title.sub")}</b>
              </h1>
            </div>
            <p>{t(basePath + "desc.first")}</p>
            <p>{t(basePath + "desc.second")}</p>
            <p>{t(basePath + "desc.third")}</p>
            <p>{t(basePath + "desc.fourth")}</p>
          </Col>
        </Row>
      </Container>
      <svg className="wave-firts" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
        <path
          fill="#fff"
          fillOpacity="1"
          d="M0,64L120,53.3C240,43,480,21,720,21.3C960,21,1200,43,1320,53.3L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
        ></path>
      </svg>
      <svg className="wave-second" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
        <path
          fill="#000"
          fillOpacity="1"
          d="M0,64L120,53.3C240,43,480,21,720,21.3C960,21,1200,43,1320,53.3L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
        ></path>
      </svg>
    </section>
  );
};

export default About;
