import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Components List
import NavBar from "../components/NavBar";
import Faq from "../pages/Faq";
import Home from "../pages/Home";
import Policy from "../pages/Policy";
import Promo from "../pages/Promo";
import Terms from "../pages/Terms";
import Wallet from "../pages/Wallet";

function Routess() {
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home/>} exact="true"/>
          <Route path="/promo" element={<Promo/>} />
          <Route path="/faq" element={<Faq/>} />
          <Route path="/policy" element={<Policy/>} />
          <Route path="/terms" element={<Terms/>} /> 
          <Route path="/exchange" element={<Wallet/>} /> 
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Routess;
