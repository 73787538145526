import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Nav, Navbar, NavDropdown, Button } from "react-bootstrap";
import { languages } from "../i18n";
import { NavLink } from "react-router-dom";
import { useLanguage } from "../contexts/LanguageContext";

const langIcons = {
  "ru-RU": require("../assets/img/flags/ru.png"),
  "en-US": require("../assets/img/flags/en.png"),
  "de-DE": require("../assets/img/flags/de.png"),
};

function NavBar() {
  // Translation
  const { t } = useTranslation();
  const basePath = "navBar.";
  const { changeLanguage } = useLanguage();

  const handleChangeLang = (e) => {
    if (e.target.className === "dropdown-item") {
      changeLanguage(e.target.dataset.value);
    }
  };

  return (
    <>
      <Navbar expand="lg" className="navbar-custom">
        <Container>
          <Navbar.Brand href="/">
            <img size="lg" src={require("../assets/img/logo.png").default} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <NavLink to="/" className="link" activeclass="active" exact={true}>
                {t(basePath + "link.home")}
              </NavLink>
              <NavLink to="/promo" className="link" activeclass="active">
                {t(basePath + "link.promo")}
              </NavLink>
              <NavLink to="/faq" className="link" activeclass="active">
                {t(basePath + "link.faq")}
              </NavLink>
            </Nav>
          </Navbar.Collapse>
          <Nav>
            <NavDropdown title={t(basePath + "lang.title")} id="collasible-nav-dropdown" onClick={handleChangeLang}>
              {languages.map((item) => (
                <NavDropdown.Item key={item} data-value={item}>
                  <img src={langIcons[item].default} alt="" />
                  {t(`${basePath}lang.${item}`)}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            <Button href="/#exchange" className="btn-st">
              {t(basePath + "btn")}
            </Button>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
