import React from "react";
import { useTranslation } from "react-i18next";

const SecondCurrency = (props) => {
  // Translation
  const { t } = useTranslation();

  const { currencyAll, chousePair = Function.prototype } = props;


  const setCur = (e) => {
    if (e.target.parentNode.id === "second_select") {
      chousePair(e);
      document.querySelector("#second_select").classList.remove("active");
      document.querySelector(".second").classList.remove("active");
      document.querySelector(".second").innerHTML = e.target.innerHTML;
    }
  };

  const handleOpen = (e) => {
    document.querySelector(".second").classList.toggle("active");
    document.querySelector("#second_select").classList.toggle("active");
  };

  return (
    <div className="select" id="secondC">
      <p className="choose second" onClick={handleOpen}>
        {t("home.exchenge.choose")}
      </p>
      <ul id="second_select">
        {/* <li onClick={setCur} id="USDT" data-value="USD">
          <span>
            <img src={require(`../../../assets/img/crypto/USD.svg`).default} alt="" />
          </span>
          USD
        </li> */}
        {/* <li onClick={setCur} id="TRY" data-value="TRY">
          <span>
            <img src={require(`../../../assets/img/crypto/TRY.jpg`).default} alt="" />
          </span>
          TRY
        </li> */}
        {/* <li onClick={setCur} id="RUB" data-value="RUB">
          <span>
            <img src={require(`../../../assets/img/crypto/RUB.svg`).default} alt="" />
          </span>
          RUB
        </li> */}

        <li onClick={setCur} id="USDT" data-value="USDT (TRC20)">
          <span>
            <img src={require(`../../../assets/img/crypto/USDT.svg`).default} alt="" />
          </span>
          USDT (TRC20)
        </li>

        <li onClick={setCur} id="USDT" data-value="USDT (ERC20)">
          <span>
            <img src={require(`../../../assets/img/crypto/USDT.svg`).default} alt="" />
          </span>
          USDT (ERC20)
        </li>
        {currencyAll.map((item, i) => (
          <li key={i} id={item} onClick={setCur} data-value={item}>
            <span>
              <img src={require(`../../../assets/img/crypto/${item}.svg`).default} alt="" />
            </span>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SecondCurrency;
