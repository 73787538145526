import React from "react";

const IconWallet = ({ width = "24px", height = "24px", fill = "#000", ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24" />
        <circle fill={fill} opacity="0.3" cx="20.5" cy="12.5" r="1.5" />
        <rect
          fill={fill}
          opacity="0.3"
          transform="translate(12.000000, 6.500000) rotate(-15.000000) translate(-12.000000, -6.500000) "
          x="3"
          y="3"
          width="18"
          height="7"
          rx="1"
        />
        <path
          d="M22,9.33681558 C21.5453723,9.12084552 21.0367986,9 20.5,9 C18.5670034,9 17,10.5670034 17,12.5 C17,14.4329966 18.5670034,16 20.5,16 C21.0367986,16 21.5453723,15.8791545 22,15.6631844 L22,18 C22,19.1045695 21.1045695,20 20,20 L4,20 C2.8954305,20 2,19.1045695 2,18 L2,6 C2,4.8954305 2.8954305,4 4,4 L20,4 C21.1045695,4 22,4.8954305 22,6 L22,9.33681558 Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default IconWallet;
