import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IconChartLine, IconCreditCard, IconSale, IconSupport } from "../../../utils/Icons";

const Feature = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "home.exchenge.feature.";

  return (
    <Row className="justify-content-center">
      <Col lg={10}>
        <Row className="justify-content-center">
          <Col lg={3} md={6}>
            <div className="element mt-20">
              <IconChartLine width="28px" height="28px" fill="#fb725a" />
              <p>{t(basePath + "course")}</p>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="element">
              <IconCreditCard width="28px" height="28px" fill="#fb725a" />
              <p>{t(basePath + "refill")}</p>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="element">
              <IconSale width="28px" height="28px" fill="#fb725a" />
              <p>{t(basePath + "commission")}</p>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="element mt-20">
              <IconSupport width="28px" height="28px" fill="#fb725a" />
              <p>{t(basePath + "support")}</p>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Feature;
