import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IconAlarmClock, IconGift, IconSafe, IconSupport } from "../../utils/Icons";

const Advantage = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "home.advantage.";

  return (
    <section className="advantage">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="title">
              <span>{t(basePath + "title.small")}</span>
              <h1>
                {t(basePath + "title.main")} <b>{t(basePath + "title.sub")}</b>
              </h1>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center mt-50">
          <Col lg={4}>
            <div className="item">
              <div className="header">
                <IconSafe width="24px" height="24px" fill="#fb725a" />
                <h4>{t(basePath + "safely.title")}</h4>
              </div>
              <p>{t(basePath + "safely.desc")}</p>
            </div>

            <div className="item">
              <div className="header">
                <IconGift width="26px" height="26px" fill="#fb725a" />
                <h4>{t(basePath + "bonuses.title")}</h4>
              </div>
              <p>{t(basePath + "bonuses.desc")}</p>
            </div>
          </Col>
          <Col lg={4}>
            <img src={require("../../assets/img/backgrounds/advantage_bg.png").default} alt="" />
          </Col>
          <Col lg={4}>
            <div className="item">
              <div className="header">
                <IconAlarmClock width="26px" height="26px" fill="#fb725a" />
                <h4>{t(basePath + "fast.title")}</h4>
              </div>
              <p>{t(basePath + "fast.desc")}</p>
            </div>
            <div className="item">
              <div className="header">
                <IconSupport width="26px" height="26px" fill="#fb725a" />
                <h4>{t(basePath + "support.title")}</h4>
              </div>
              <p>{t(basePath + "support.desc")}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Advantage;
