import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, FormControl, InputGroup, Modal } from "react-bootstrap";
import { send } from "emailjs-com";
import { ExchangeContext } from "../../../contexts/ExchangeContext";
import { IconCall, IconDoneCircle, IconErrorCircle, IconMail, IconTicket, IconUser, IconWallet } from "../../../utils/Icons";
import ModalSuccess from "./ModalSuccess";
const ModalSend = (props) => {
  const navigate = useNavigate();
  // Translation
  const { t } = useTranslation();
  const basePath = "home.exchenge.modalSend.";
  const { setModalSendShow = Function.prototype } = props;
  const date = new Date
  const {
    setFirstCurrency,
    setFirstCurrencyInner,
    setSecondCurrencyInner,
    setSecondCurrency,
    setResalt,
    setAmount,
    toWallet,
    setToWallet,
    firstCurrency,
    secondCurrency,
    firstCur,
    secondCur,
    resalt,
    amount,
    firstCurrencyInner,
    secondCurrencyInner,
    ourWallet,
  } = useContext(ExchangeContext);
  const [modalSuccessShow, setModalSuccessShow] = useState(false);


  const [toSend, setToSend] = useState({
    name: "",
    email: "",
    wallet: "",
    number: "",
    message: "",
    promo: "",
    id:Date.now(),
    amount: amount,
    firstCurrencyInner: firstCurrencyInner,
    toWallet: toWallet,
    resalt:(resalt * Number(amount)).toFixed(4),
    secondCurrencyInner:secondCurrencyInner
  });



  let textLira =
    secondCurrencyInner === "TRY" || secondCurrencyInner === "RUB"
      ? String(
          "После подтверждения оплаты Вам на почту будет выслан доступ к Вашему личному кабинету с актуальным балансом." +
            "\n" +
            " Письмо будет содержать: " +
            "\n" +
            "• Логин" +
            "\n" +
            "• Пароль " +
            "\n" +
            " • Инструкцию" +
            "\n"
        )
      : "";

  useEffect(() => {
    setToSend({
      ...toSend,
      promo: "",
      amount: amount,
      firstCurrencyInner: firstCurrencyInner,
      toWallet: toWallet,
      resalt:(resalt * Number(amount)).toFixed(4),
      secondCurrencyInner:secondCurrencyInner
    });

    // setToSend({
    //   ...toSend,
    //   ["message"]:
    //     amount + " : " + firstCurrencyInner + " в " + secondCurrencyInner + " = " + (resalt * Number(amount)).toFixed(4),
    // });
  }, [firstCur, secondCur, amount, resalt]);

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  const сlearAll = () => {
    setFirstCurrency(null);
    setSecondCurrency(null);
    setToWallet(null);
    setFirstCurrencyInner(null);
    setSecondCurrencyInner(null);
    setResalt(0);
    setAmount(0);
    setModalSendShow(false);
    setModalSuccessShow(true);
    document.querySelector(".amount").innerHTML = "";
    document.querySelector(".amount").value = "";
    document.querySelector(".first").innerHTML = t("home.exchenge.choose");
    document.querySelector(".second").innerHTML = t("home.exchenge.choose");
    setToSend({
      name: "",
      email: "",
      wallet: "",
      number: "",
      message: "",
      promo: "",
      id:Date.now(), 
      amount: amount,
      firstCurrencyInner: firstCurrencyInner,
      toWallet: toWallet,
      resalt:(resalt * Number(amount)).toFixed(4),
      secondCurrencyInner:secondCurrencyInner
    });
  
  };

  const onSubmit = () => {
    if (
      toSend.name === "" ||
      toSend.number === "" ||
      toSend.wallet === "" ||
      toSend.email === ""
    ) {
      document.querySelector(".error").classList.add("active");
      document.querySelector(".success").classList.remove("active");
    } else {
      localStorage.setItem("firstCurrencyInner", firstCurrencyInner);
      localStorage.setItem("amount", amount);
      localStorage.setItem("id", toSend.id);
      localStorage.setItem("secondCurrencyInner", secondCurrencyInner);
      localStorage.setItem("wallet", toSend.wallet);
      localStorage.setItem("toWallet", toWallet);
      localStorage.setItem("resalt", toSend.resalt);


      send("service_py18a4l", "template_rqesphn", toSend, "DSaKe6lqbnuNGCxFg")
        .then((response) => {
         сlearAll();
          document.querySelector(".error").classList.remove("active");
          document.querySelector(".success").classList.add("active");
          let id = localStorage.getItem('id')
          if(id)alert('При создании новой заявки старая будет закрыта')
          navigate('/exchange')
        })
        .catch((err) => {
          document.querySelector(".error").classList.add("active");
          document.querySelector(".success").classList.remove("active");
        });
    }
  };

  return (
    <>
      <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="send-header">
            <img src={require("../../../assets/img/backgrounds/send_bg.png").default} alt="" />
            <p>{t(basePath + "title")}</p>
          </div>
          <InputGroup>
            <InputGroup.Text id="inputGroupPrepend">
              <IconUser width="20px" height="20px" fill="#fb725a" />
            </InputGroup.Text>
            <FormControl
              type="text"
              name="name"
              placeholder={t(basePath + "form.name")}
              value={toSend.name}
              onChange={handleChange}
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text id="inputGroupPrepend">
              <IconCall width="20px" height="20px" fill="#fb725a" />
            </InputGroup.Text>
            <FormControl
              type="number"
              name="number"
              placeholder={t(basePath + "form.number")}
              value={toSend.number}
              onChange={handleChange}
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text id="inputGroupPrepend">
              <IconMail width="20px" height="20px" fill="#fb725a" />
            </InputGroup.Text>
            <FormControl
              type="email"
              name="email"
              placeholder={t(basePath + "form.email")}
              value={toSend.email}
              onChange={handleChange}
            />
          </InputGroup>


            <InputGroup>
              <InputGroup.Text id="inputGroupPrepend">
                <IconWallet width="20px" height="20px" fill="#fb725a" />
              </InputGroup.Text>
              <FormControl
                type="text"
                name="wallet"
                placeholder={t(basePath + "form.wallet")}
                value={toSend.wallet}
                onChange={handleChange}
              />
            </InputGroup>
          

          <InputGroup>
            <InputGroup.Text id="inputGroupPrepend">
              <IconTicket width="20px" height="20px" fill="#fb725a" />
            </InputGroup.Text>
            <FormControl
              type="text"
              name="promo"
              placeholder={t(basePath + "form.promo")}
              value={toSend.promo}
              onChange={handleChange}
            />
          </InputGroup>

          <div className="valid">
            <span className="invalid-feedback error">
              <IconErrorCircle width="20px" height="20px" fill="#dc3545" />
              {t(basePath + "error")}
            </span>
            <span className="invalid-feedback success">
              <IconDoneCircle width="20px" height="20px" fill="#47d071" />
              {t(basePath + "success")}
            </span>
          </div>

          <div className="btn-groups justify-content-center mt-50">
            <Button className="btn-st" onClick={onSubmit}>
              {t(basePath + "btn")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal Success Component */}
      <ModalSuccess show={modalSuccessShow} onHide={() => setModalSuccessShow(false)} />
    </>
  );
};

export default ModalSend;
