import React from "react";
import Routess from "./router/Routes";
import { LanguageProvider } from "./contexts/LanguageContext";
import { ExchangeProvider } from "./contexts/ExchangeContext";

const App = () => {

  return (
    <>
      <LanguageProvider>
        <ExchangeProvider>
          <Routess />
        </ExchangeProvider>
      </LanguageProvider>
    </>
  );
};

export default App;
