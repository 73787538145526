import React from "react";
import useCopy from "../utils/useCopy";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { Col, Container, FormControl, InputGroup, Row } from "react-bootstrap";
import { IconDuplicate, IconTicket, IconTimer } from "../utils/Icons";
import Footer from "../components/Footer";

const Promo = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "promo.";

  const { copyToClipboard, copyText } = useCopy();

  return (
    <>
      <section className="promo">
        <Container>
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="content">
                <img src={require("../assets/img/backgrounds/promo_bg.png").default} alt="" />
                <div className="info">
                  <div class="title">
                    <span>{t(basePath + "title.small")}</span>
                    <h1>{t(basePath + "title.main")}</h1>
                  </div>

                  <InputGroup>
                    <InputGroup.Text id="inputGroupPrepend">
                      <IconTicket width="18px" height="18px" fill="#fb725a" />
                    </InputGroup.Text>
                    <FormControl id="copy-input-promo" value="ObmenHouse" readOnly />
                    <span className="copy" onClick={copyToClipboard} data-value="promo" data-tip={copyText}>
                      <IconDuplicate width="20px" height="20px" fill="#000" />
                    </span>
                  </InputGroup>

                  <p>
                    <IconTimer width="25px" height="25px" fill="#fff" />
                    {t(basePath + "time")}
                  </p>
                  <ReactTooltip />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <svg className="wave-firts" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,64L120,53.3C240,43,480,21,720,21.3C960,21,1200,43,1320,53.3L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
        <svg className="wave-second" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
          <path
            fill="#000"
            fillOpacity="1"
            d="M0,64L120,53.3C240,43,480,21,720,21.3C960,21,1200,43,1320,53.3L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </section>
      <Footer />
    </>
  );
};

export default Promo;
