import React from "react";

const IconSupport = ({ width = "24px", height = "24px", fill = "#000", ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 52 52">
      <path
        fill={fill}
        opacity=".3"
        d="M43.842 39.03c-.53-1.171-1.55-1.581-2.02-1.711l-6.73-4.29c-.1-.08-1.05-.77-2.44-.77-1.21 0-3.01.54-4.67 3.13-.54.85-1.15.95-1.56.95-1.04 0-2.99-.73-6.29-4.17l-.21-.22c-.45-.44-3.08-3.59-4.68-5.54-.2-.29-1.85-2.91.55-4.88 3.12-2.57 2.31-5.4 1.47-6.5l-4.39-6.45c-.15-.2-1.49-2-3.18-2-.42 0-.83.12-1.2.33-.52.26-4.35 2.27-5.88 6.63-1.13 3.25-.7 6.92 1.29 10.93 5.83 11.7 22.3 22.29 23 22.74.04.02.08.05.13.07.15.07 3.9 1.72 7.83 1.72 3.35 0 5.91-1.23 7.4-3.57 1.73-2.71 2.26-4.86 1.58-6.4zm-3.26 5.319c-1.13 1.76-3.05 2.65-5.72 2.65-3.23 0-6.46-1.31-6.95-1.52-1.15-.74-16.81-11.05-22.21-21.9-1.74-3.5-2.15-6.64-1.21-9.36 1.32-3.79 4.86-5.5 4.9-5.52.03-.02.07-.03.1-.05.07-.05.13-.07.2-.07.46 0 1.2.68 1.54 1.15l4.4 6.46c.22.3 1.19 1.9-1.11 3.79-3.09 2.55-1.96 6.06-.88 7.63.43.52 4.21 5.12 4.87 5.77l.18.18c3.08 3.21 5.62 4.78 7.73 4.78 1.34 0 2.46-.65 3.24-1.87.65-1.01 1.7-2.21 2.99-2.21.72 0 1.23.36 1.29.41l6.94 4.42c.12.08.24.12.38.15 0 0 .53.11.75.61.18.4.4 1.64-1.43 4.5z"
      />
      <path
        fill={fill}
        opacity=".3"
        d="M40.582 44.349c-1.13 1.76-3.05 2.65-5.72 2.65-3.23 0-6.46-1.31-6.95-1.52-1.15-.74-16.81-11.05-22.21-21.9-1.74-3.5-2.15-6.64-1.21-9.36 1.32-3.79 4.86-5.5 4.9-5.52.03-.02.07-.03.1-.05.07-.05.13-.07.2-.07.46 0 1.2.68 1.54 1.15l4.4 6.46c.22.3 1.19 1.9-1.11 3.79-3.09 2.55-1.96 6.06-.88 7.63.43.52 4.21 5.12 4.87 5.77l.18.18c3.08 3.21 5.62 4.78 7.73 4.78 1.34 0 2.46-.65 3.24-1.87.65-1.01 1.7-2.21 2.99-2.21.72 0 1.23.36 1.29.41l6.94 4.42c.12.08.24.12.38.15 0 0 .53.11.75.61.18.4.4 1.64-1.43 4.5z"
      />
      <path
        fill={fill}
        d="M43.58 36.951a1 1 0 0 1-.71-1.703c3.309-3.345 5.132-7.762 5.132-12.437 0-9.82-8.1-17.81-18.056-17.81-4.612 0-9.002 1.714-12.36 4.825a1 1 0 1 1-1.36-1.466C19.956 4.905 24.828 3 29.946 3c11.06 0 20.056 8.887 20.056 19.81 0 5.205-2.028 10.12-5.71 13.844a.994.994 0 0 1-.712.296z"
      />
      <path
        fill={fill}
        d="M29.998 9.742a1 1 0 01-1-.989l-.052-4.741a1 1 0 01.989-1.01h.01a1 1 0 011 .988l.053 4.742a1 1 0 01-.989 1.01h-.01zM44.091 23.864a1 1 0 01-.01-2l4.91-.053h.011a1 1 0 01.01 2l-4.91.053h-.011zM21.1 25.44v-.967l1.254-1.201c3.017-2.833 4.38-4.339 4.398-6.097 0-1.182-.582-2.276-2.345-2.276-1.072 0-1.962.537-2.508.985l-.508-1.111a5.232 5.232 0 013.343-1.184c2.545 0 3.618 1.722 3.618 3.389 0 2.151-1.581 3.89-4.072 6.258l-.945.86v.036h5.307v1.309H21.1zM36.872 21.03v-7.241h-1.73l-5.27 7.44v1.04h5.49v3.17h1.51v-3.17h1.65v-1.24h-1.65zm-1.51-3.891v3.89h-3.87V21l2.89-3.97c.33-.53.62-1.04.98-1.72h.06c-.04.61-.06 1.22-.06 1.83z"
      />
    </svg>
  );
};

export default IconSupport;
