import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import { IconQuestionCircle } from "../utils/Icons";

const Faq = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "faq.";

  let faqContent = [].concat(t(basePath + "faqContent", { returnObjects: true }));

  return (
    <>
      <section className="faq">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <div className="header">
                <h1>{t(basePath + "title")}</h1>
                <img src={require("../assets/img/backgrounds/faq_bg.png").default} alt="" />
              </div>
              <Accordion defaultActiveKey="0">
                {faqContent.map((item, i) => (
                  <Accordion.Item eventKey={i}>
                    <Accordion.Header>
                      <IconQuestionCircle width="20px" height="20px" fill="#fb725a" /> {item.title}
                    </Accordion.Header>
                    <Accordion.Body>{item.desc}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Col>
          </Row>
        </Container>
        <svg className="wave-firts" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
          <path
            fill="#fff"
            fillOpacity="1"
            d="M0,64L120,53.3C240,43,480,21,720,21.3C960,21,1200,43,1320,53.3L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
        <svg className="wave-second" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100">
          <path
            fill="#000"
            fillOpacity="1"
            d="M0,64L120,53.3C240,43,480,21,720,21.3C960,21,1200,43,1320,53.3L1440,64L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
          ></path>
        </svg>
      </section>
      <Footer />
    </>
  );
};

export default Faq;
