import React from "react";
import { useTranslation } from "react-i18next";

const FirstCurrency = (props) => {
  // Translation
  const { t } = useTranslation();

  const { currencyAll, chousePair = Function.prototype } = props;



  const setCur = (e) => {
    if (e.target.parentNode.id === "first_select") {
      chousePair(e);
      document.querySelector("#first_select").classList.remove("active");
      document.querySelector(".first").classList.remove("active");
      document.querySelector(".first").innerHTML = e.target.innerHTML;
    }
  };

  const handleOpen = (e) => {
    document.querySelector(".first").classList.toggle("active");
    document.querySelector("#first_select").classList.toggle("active");
  };

  return (
    <div className="select" id="firstC">
      <p className="choose first" onClick={handleOpen}>
        {t("home.exchenge.choose")}
      </p>
      <ul id="first_select">
        {/* <li onClick={setCur} id="USDT" data-value='USD'>
          <span>
            <img src={require(`../../../assets/img/crypto/USD.svg`).default} alt="" />
          </span>
          USD
        </li> */}
        <li onClick={setCur} id="USDT" data-value='USDT (TRC20)'>
          <span>
            <img src={require(`../../../assets/img/crypto/USDT.svg`).default} alt="" />
          </span>
          USDT (TRC20)
        </li>
        <li onClick={setCur} id="USDT" data-value='USDT (ERC20)' >
          <span>
            <img src={require(`../../../assets/img/crypto/USDT.svg`).default} alt="" />
          </span>
          USDT (ERC20)
        </li>
        {currencyAll.map((item, i) => (
          <li key={i} id={item} onClick={setCur} data-value={item}>
            <span>
              <img src={require(`../../../assets/img/crypto/${item}.svg`).default} alt="" />
            </span>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FirstCurrency;
