import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Container, Row, Button } from "react-bootstrap";

const Footer = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "footer.";

  return (
    <footer>
      <Container>
        <Row className="align-items-center">
          <Col lg={4} md={6}>
            <div className="left-side">
              <p>{t(basePath + "desc")}</p>
              <p>obmen.house.help@gmail.com</p>
              <div className="d-flex">
                <Button href="/#exchange" className="btn-st">
                  {t(basePath + "btn")}
                </Button>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className="center-side">
              <img src={require("../assets/img/logo.png").default} className="logo" alt="" />
            </div>
          </Col>
          <Col lg={4} md={12}>
            <div className="right-side">
              <h5>{t(basePath + "link.title")}</h5>
              <ul>
                <li>
                  <Link to="/promo">{t(basePath + "link.promo")}</Link>
                </li>
                <li>
                  <Link to="/faq">{t(basePath + "link.faq")}</Link>
                </li>
                <li>
                  <Link to="/terms">{t(basePath + "link.terms")}</Link>
                </li>
                <li>
                  <Link to="/policy">{t(basePath + "link.privacy")}</Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
