export const TRANSLATIONS_EN = {
  navBar: {
    link: {
      home: "Home",
      promo: "Promo code",
      faq: "FAQ",
    },

    lang: {
      title: "Language",
      "en-US": "English",
      "ru-RU": "Russian",
      "de-DE": "Deutsch",
    },

    btn: "Exchange",
    etention: "Überprüfen Sie vor dem Absenden die Richtigkeit Ihrer Empfangsbestätigung!"
  },

  home: {
    banner: {
      title: "Do you want to get the best exchange rate?",
      desc: "We are glad to offer a reliable, profitable, instant and convenient service for depositing / withdrawing electronic currencies.",
      btn: "Exchange",
    },

    exchenge: {
      title: {
        main: "Сurrency",
        sub: "Exchange",
      },

      give: "Give",
      receive: "Receive",
      amount: "Quantity",
      choose: "Choose a currency",
      btn: "Exchange",

      agreement: {
        first: 'By clicking on the "Exchange" button, you agree with',
        link: "User Agreement",
        second: "our resource",
      },

      modalError: {
        title: "Error! Please complete all fields.",
      },

      modalSuccess: {
        title: "Application sent! Please check your mail"
      },

      modalSend: {
        title: "Fill out the form to send an application!",
        form: {
          name: "Enter your name *",
          number: "Enter your phone number *",
          email: "Enter your email @gmail.com*",
          wallet: "Enter your wallet *",
          promo: "Enter promo code (if available)",
        },
        error: "Fill in all input fields, mail @gmail.com",
        success: "The application has been sent, wait for a response from the manager",
        btn: "Submit an inquiry",
      },

      feature: {
        course: "The most profitable course",
        refill: "From 50$ replenishment",
        commission: "0.5% - 1% Commission",
        support: "Support 24/7",
      },
    },

    advantage: {
      title: {
        small: "Our advantages",
        main: "Why choose",
        sub: "us?",
      },

      safely: {
        title: "Safely",
        desc: "With us, you do not have to worry about the safety of your funds.",
      },
      bonuses: {
        title: "Bonuses",
        desc: "Daily promotional code-discount on commission in the amount of 1-3%",
      },
      fast: {
        title: "Fast",
        desc: "We provide fast exchange of your funds",
      },
      support: {
        title: "Quick Support",
        desc: "Our managers are ready to provide you with professional, fast support",
      },
    },

    about: {
      title: {
        small: "About company",
        main: "Profitable and convenient",
        sub: "service",
      },

      desc: {
        first:
          "The currency converter provides an opportunity to convert the required amount online from one currency to another.",
        second:
          "The converter performs all conversions on the basis of official data on quotations.",
        third: "Information about currency quotes is updated every hour.",
        fourth: "The online currency converter is very easy to use and will save you time.",
      },
    },
  },

  promo: {
    title: {
      small: "Promo code",
      main: "Your discount promo code",
    },
    time: "Valid until 22.07.2023",
  },

  faq: {
    title: "Frequently asked Questions",
    faqContent: [
      {
        title: "How does the exchanger work?",
        desc: "The exchanger is fully automatic. You can make exchanges 24/7/365. Payment is made instantly after your funds are credited to our wallet. Information about the status of the application is updated on the payment page and duplicated by mail.",
      },
      {
        title: "Are you using AML validation?",
        desc: 'Yes, we use AML to check cryptocurrencies for risks using our partner\'s "Crystal Blockchain" system. This check identifies cryptocurrencies that were stolen from their owners, received fraudulently, passed through a "mixer" or were involved in the sale of prohibited goods on Darknet sites. If such a cryptocurrency is detected by our system, your application receives the status "Risky payment".',
      },
      {
        title: "What are the terms for crediting funds?",
        desc: 'Payment is made instantly after your funds are credited to our wallet. If the application is in the "Completed" status, then the funds have been debited from our account and sent to the details you specified.',
      },
      {
        title: "Is it possible for me to cancel the application?",
        desc: "You can cancel the application by writing to the technical support chat on the site. In this case, the return will be made with a service commission of 10% and after your funds are credited to our wallet. A completed application cannot be cancelled.",
      },
      {
        title: "Do you fix the exchange rate?",
        desc: "Yes, the rate is fixed for 90 minutes for Bitcoin and 30 minutes for other cryptocurrencies. After this time, the application remains valid and will be executed according to the terms of the exchange.",
      },
      {
        title: "What will happen to the application when the time for fixing the rate passes?",
        desc: "The application will remain valid and will be automatically executed upon receipt of confirmations in the blockchain. If the exchange rate falls, you will receive an email with information to independently recalculate the application at the current exchange rate or issue a refund. If you do not make a decision within 12 hours, the application will be recalculated automatically at the current rate. If the rate rises, the order will be executed automatically at the rate at the time of its creation.",
      },
      {
        title: "What are the application statuses?",
        desc: 'Statuses change during the exchange and are available on the payment page. Also, statuses are duplicated in the mail. "Awaiting payment" - the application has been created, but the funds have not yet been credited to our wallet. "Awaiting confirmation" - the transaction has entered the blockchain and is waiting for confirmation from the miners in the network. "Completed" - the funds have been sent to the details you specified, expect crediting, according to the regulations. "Closed" - we have not received payment for the application, the application is closed after the expiration of time. If payment is received, the application will be executed. "Paid. The rate has changed" - the time of fixing the rate has passed and it has decreased. You need to recalculate or issue a refund. "Risky payment" - Your cryptocurrency was previously used on prohibited Darknet sites or passed through the "Mixer". Please issue a refund. "Invalid payment amount" - you paid less than the minimum amount for the exchange. Please issue a refund. "Payment declined" - we were unable to send funds to the specified details, possibly due to limits. You can repeat the payment yourself or change the card number in your personal account or through a link sent to the mail. "Paid in part" - we were unable to send part of the funds to the specified details, possibly due to limits. You can repeat the payment yourself or change the card number in your personal account or through a link sent to the mail.',
      },
      {
        title: '"Awaiting confirmation" - what is it and who should confirm?',
        desc: "A cryptocurrency transfer is considered completed when it receives the required number of confirmations in the blockchain network. After 2 confirmations for Bitcoin and 6 for Ethereum and other cryptocurrencies, an automatic payment will be made. The speed of receiving confirmations is affected by the commission you set for the transfer and the network load. Since it is no longer possible to cancel a transaction and influence its speed, all that remains is to wait for confirmation.",
      },
      {
        title: "I paid less than what was indicated in the application.",
        desc: "Not a problem. After confirmations in the blockchain, an automatic recalculation will be performed for the amount we received, taking into account the difference in the exchange rate, if it falls.",
      },
      {
        title: '"Risky payment" - what is it and what to do?',
        desc: 'All exchanges, as well as some resources, use a cryptocurrency risk check. We use the top system "Crystal Blockchain". The status "Risky payment" means that most of the cryptocurrency that you sent us was previously involved in the sale of prohibited goods on Darknet marketplaces, was passed through the "Mixer" in order to launder funds, or was stolen. If the application has got into this status, you need to independently issue a refund on the payment page or through the link in the letter in the mail.',
      },
      {
        title: "I paid and the application closed.",
        desc: 'First of all, check the withdrawal of currency from your wallet. Also keep in mind that some cryptocurrency storage resources send with a delay. In this case, the status of the application "Closed" is temporary and it will be executed after the currency is credited to our wallet.',
      },
      {
        title: "What is the return fee?",
        desc: 'The commission for the return of the cryptocurrency is 10 USDT, 0.003 ETH, 0.0003 BTC. In the case of "Risky payment", the return is made minus the current commission in the blockchain. If the application is canceled at the initiative of the client, the return will be made with a service commission of 10% and after crediting your funds to our wallet. The return of a cryptocurrency with which our service does not work, or coins that were mistakenly sent to the wrong network, is made minus 10% of the exchange amount.',
      },
      {
        title: "I made a mistake with the network/cryptocurrency when paying for the application.",
        desc: "Our experts will consider a specific case and within 24 hours will inform you about the possibility of returning such a cryptocurrency. If there is a technical possibility, the return will be made within 10 days minus 10% of the application amount.",
      },
    ],
  },

  copy: {
    copy: "Copy",
    success: "Copied",
  },

  policy: {
    title: {
      main: "Privacy",
      sub: "Policy",
    },
    policyContent: [
      {
        number: "1",
        title: "Main",
        desc: [
          {
            title:
              "1.1. Please read the following terms regarding the information storage services provided by Obmen-House. Obmen-House reserves the right to change the Privacy Policy at any time without prior notice. Only the online privacy statement is valid, so please read the website's Privacy Statement periodically. These provisions were developed by our team in order to show the concern of the Obmen-House service for the safety of the information of its customers. We want to show that storing information using https://obmen-house.io is absolutely safe. Below you can read the privacy policy.",
          },
          {
            title:
              "1.2. The Obmen-House service receives personal data from the User during registration or when placing an Application and fixes them in the database.",
          },
          {
            title:
              "1.3. The service uses cookies, data about the user's IP address, information about the user's browser, for the operation of the software and hardware of the Website.",
          },
          {
            title: "1.4. Obmen-House has the right to request additional information about the user's personal data.",
          },
          {
            title:
              "1.5. The user's personal data can be used by the service solely for the purpose of ensuring the operation of the Service and cannot be transferred to third parties, except as provided by law, the rules of exchange offices for a particular EPS and international law.",
          },
        ],
      },
      {
        number: "2",
        title: "Users' personal data",
        desc: [
          {
            title:
              "2.1. When registering, users leave some information, namely: login, password, name, contact information, for example, email address. This information is used to provide the Obmen-House services. Registration information (login, password) - for user authentication, contact information (name, email address) is used to provide information to users about new features of the system. We do not provide contact information to third parties without your consent.",
          },
          {
            title:
              "2.2. Obmen-House complies with the law - in rare cases, subject to all legal regulations, user information may be transferred to law enforcement agencies. Obmen-House may also collect and analyze certain visitor and user information relating to the use of Obmen-House services. The information collected may include traffic volume, frequency of visits, transaction type and time, browser and operating system type, etc. This information is recorded to help diagnose technical problems, administer the site, and improve the quality and types of services provided.",
          },
        ],
      },
      {
        number: "3",
        title: "Cookies",
        desc: [
          {
            title:
              "3.1. Obmen-House uses temporary and permanent cookies. Temporary cookies will be deleted from your computer each time you close your browser.",
          },
          {
            title:
              '3.2. By choosing "Remember me" in the Obmen-House service, you will save your login and password on your computer, which means that when you return to the site, you will not have to enter them again. If you have cookies disabled, you will not be able to access some parts of the website.',
          },
        ],
      },
      {
        number: "4",
        title: "Third Party Sites",
        desc: [
          {
            title:
              "4.1. Our site contains links to other sites on the Internet that have different privacy policies from Obmen-House. Obmen-House does not provide any guarantees regarding the security policy of sites referred to by the Obmen-House service or their users.",
          },
          {
            title:
              "4.2. We encourage you to read the privacy policies available on these sites to better understand your rights.",
          },
          {
            title:
              "4.3. Obmen-House will never sell or share your personal information with third parties for marketing or any other purpose without your permission.",
          },
        ],
      },
      {
        number: "5",
        title: "Security",
        desc: [
          {
            title:
              "5.1. Our specialists develop modern security systems to ensure reliable protection of user information. Each user receives a username and password upon registration.",
          },
          {
            title:
              "5.2. It is in the interests of users not to disclose their password and login to third parties. These measures will help prevent unauthorized access and ensure the proper use of data.",
          },
        ],
      },
    ],
  },

  terms: {
    title: {
      main: "Terms of",
      sub: "use",
    },
    termsContent: [
      {
        number: "1",
        title: "Terms and Definitions",
        desc: [
          {
            title:
              "1.1. Obmen-House is an automated web service located on the Internet at https://obmen-house.io and offering its services using a special software interface for all Users (hereinafter referred to as the Service).",
          },
          {
            title:
              "1.2. User is any natural or legal person who has agreed with all the conditions offered by the Service and acceded to this agreement.",
          },
          {
            title:
              "1.3. Electronic units (title units) - accounting units of the relevant electronic settlement systems, denoting a certain amount of rights of claim or other rights arising from the contract of electronic settlement systems with their users.",
          },
          {
            title:
              "1.4. An electronic settlement system (payment system) is a software and hardware product developed by a third party and is a system for implementing the accounting of electronic units, as well as organizing mutual settlements between its users.",
          },
          {
            title: "1.5. Payment is the transfer of electronic units from the payer to the recipient.",
          },
          {
            title:
              "1.6. Application - information submitted by the User using the means of the Service in electronic form, indicating his intention to use the services of the Service on the terms offered by the Service and specified in the parameters of the application.",
          },
        ],
      },
      {
        number: "2",
        title: "General provisions",
        desc: [
          {
            title:
              "2.1. This agreement governs the relationship between the User and the Service regarding the services provided by the Service to the User and cancels all previous agreements between the Service and the User on this subject.",
          },
          {
            title:
              "2.2. This agreement does not cancel the current legislation of the countries of registration and location of the User, as well as contractual relations between the User and the System (systems) of electronic settlements.",
          },
          {
            title:
              "2.3. This agreement is considered accepted on the terms of a public offer accepted by the User during the submission of the Application, which is an integral part of this agreement.",
          },
          {
            title:
              "2.3.1. The information displayed by the Service about the parameters and conditions of the Application is recognized as a public offer.",
          },
          {
            title:
              "2.3.2. The acceptance of a public offer is recognized as the User's actions to complete the formation of the Application, confirming his intention to make a deal with the Service on the terms proposed by the Service immediately before the completion of the formation of the Application.",
          },
          {
            title:
              "2.3.3. The date and time of acceptance, as well as the parameters of the conditions of the Application are fixed by the Service automatically at the moment the formation of the Application is completed.",
          },
          {
            title:
              "2.3.4. The period of acceptance of the offer by the User to make a transaction with the Service on the terms described in the User's Application is 30 minutes from the moment the Application is completed.",
          },
          {
            title: "2.4. This agreement comes into effect from the moment the User completes the formation of the Application.",
          },
          {
            title:
              "2.5. This agreement terminates from the moment the details provided by the User receive Electronic Units in the amount provided for by the parameters of the User's Application, or from the moment the application is cancelled.",
          },
          {
            title:
              "2.6. The parties recognize this agreement in electronic form as equivalent in legal force to an agreement concluded in writing.",
          },
          {
            title:
              '2.7. The Service reserves the right to unilaterally make changes to the discount system as well as to the partner reward system without corresponding notice to the User, but with the obligatory publication of the current version of the deduction system on the "Help" page, sections "Exchange Bonus" and "Affiliate Program" .',
          },
          {
            title:
              "2.8. The Service reserves the right to unilaterally make changes to this agreement without a corresponding notice to the User, but with the obligatory publication of the current version of the agreement on this page.",
          },
        ],
      },
      {
        number: "3",
        title: "Subject of the agreement",
        desc: [
          {
            title:
              "3.1. Using the technical means of the Service, by submitting an Application, the User instructs, and the Service, on its own behalf and at the expense of the User, performs for a fee actions to accept and further transfer the amount of Electronic Units declared by the User to a person or persons wishing to purchase them for a monetary equivalent in an amount not lower than provided in the parameters of the Application submitted by the User, as well as actions to transfer the monetary equivalent in the amount specified in the application parameters to the details specified by the User.",
          },
          {
            title:
              "3.2. Any positive difference resulting from the actions described in paragraph 3.1 of this agreement, as an additional benefit, is transferred to the Service as a premium payment for commission services.",
          },
        ],
      },
      {
        number: "4",
        title: "Terms of Service",
        desc: [
          {
            title:
              "4.1. The processing of the User's Applications is carried out by the Service in strict accordance with the privacy policy (clause 5 of this agreement), as well as the policy to combat money laundering and prevent transactions that are illegal (clause 6 of this agreement).",
          },
          {
            title:
              "4.2. Ordering the services of the Service, managing the transaction process and obtaining information about the progress of the transaction by the User are carried out exclusively using the appropriate user interface provided by the Service.",
          },
          {
            title:
              "4.3. Accounting for transactions with electronic units is carried out by the Service in accordance with the regulations, rules and format of the relevant Electronic Settlement Systems.",
          },
          {
            title:
              "4.4. Any completed operation carried out by the Service at the request of the User is considered irrevocable, i.e. cannot be canceled by the User after its completion - receipt by the User of what is due to him under previously accepted terms of the transaction.",
          },
          {
            title:
              "4.5. If the User does not receive electronic units within 15 minutes from the moment the User accepts the details provided by the Service, the agreement on the terms specified in the Application is considered unilaterally terminated by the Service as not having entered into force, without notifying the User about it.",
          },
          {
            title:
              "4.5.1. In case of termination of the agreement, Electronic units received after the above period shall be returned to the sender's details within the next 24 hours. When making a return, all commission expenses for the transfer of electronic units are made from the funds received at the expense of the User. The Service is not responsible for possible delays in the return, if they arose through no fault of the Service.",
          },
          {
            title:
              "4.6. If the number of received Electronic Units differs from that declared by the User, the Service may unilaterally terminate the agreement by refusing to execute the application and returning the funds received to the sender's details within the next 24 hours. When making a return, all commission expenses for the transfer of Electronic Units are made from the funds received at the expense of the User. The Service is not responsible for possible delays in the return, if they arose through no fault of the Service.",
          },
          {
            title:
              "4.7. If the money equivalent is not sent to the details specified by the User within 24 hours from the start of the contract, in the absence of reasons for blocking the Electronic Units received at the User's Application from the Service, the User may demand termination of the agreement by canceling his application and returning the electronic units to in full.",
          },
          {
            title:
              "4.7.1. The request to cancel the application must be fulfilled by the Service only if, at the time of receipt of such request, the monetary equivalent was not sent to the details specified by the User.",
          },
          {
            title:
              "4.7.2. In case of cancellation of the application, the return of the Electronic units is made by the Service to the details of the sender within 24 hours from the receipt of the cancellation request. The Service is not responsible for possible delays in the return, if they arose through no fault of the Service.",
          },
          {
            title: "4.8. The Service has the right to engage third-party performers to fulfill its obligations.",
          },
          {
            title:
              "4.9. The Service has the right to cancel the operation in progress and return the Electronic Units and / or funds deposited by the User with reimbursement of commissions of the Electronic Settlement Systems to the User without explanation.",
          },
          {
            title:
              "4.10. The Service has the right to refuse the User further service in case of violation by the User of any of the clauses of this agreement.",
          },
          {
            title:
              "4.10.1. If the Service refuses to further service the User, the Service notifies the User of its decision by e-mail or a phone call, after which it freezes the User's account and all current User Applications. Further, the Service returns the Electronic Units received at the User's Application to the sender's details within 24 hours from the moment of refusal. When making a return, all commission expenses for the transfer of Electronic Units are made from the funds received at the expense of the User. The Service is not responsible for possible delays in the return, if they arose through no fault of the Service.",
          },
          {
            title:
              "4.10.2. All subsequent Applications created by the User after the Service refuses to further service the User are automatically frozen. Accounts associated with these Applications are blocked.",
          },
        ],
      },
      {
        number: "5",
        title: "Privacy Policy",
        desc: [
          {
            title:
              "5.1. To carry out operations, the Service accepts from the User his personal data, which the Service undertakes to store in encrypted form, not to make public, not to transfer to third parties, except as described in clauses 5.4, 5.5 and 6.5 of this agreement.",
          },
          {
            title:
              "5.2. All operations with Applications, as well as the transfer of personal data from the User to the Service, are carried out via an encrypted SSL channel with a key length of 256 bits.",
          },
          {
            title:
              "5.3. The Service has the right, if necessary, to independently carry out activities to collect additional data about the User by any available means. All information collected as a result of such activities is not made public, is not transferred to third parties, except for the cases described in clauses 5.4, 5.5 and 6.5 of this agreement.",
          },
          {
            title:
              "5.4. The Service has the right to transfer the User's personal data and details of the operations performed by him, provided that their confidential status is maintained upon an official written request / court decision / on its own initiative (in the event of a need for an investigation) to law enforcement agencies, as well as to the User to whom they belong.",
          },
          {
            title:
              "5.5. The Service has the right to transfer the details of the operation and the personal data of the User related to the cooperation at the official request of the Electronic Settlement System for internal investigations.",
          },
          {
            title:
              "5.6. All collected data about the User, as well as details of the operations performed by him, are stored in the database of the Service for five years from the moment the Service completed the last Application of the User.",
          },
        ],
      },
      {
        number: "6",
        title: "Anti-money laundering and illegal transactions policy",
        desc: [
          {
            title:
              "6.1. In accordance with international law, Obmen-House adheres to a number of rules and implements a number of procedures aimed at preventing the use of the Service for the purpose of conducting money laundering operations, that is, actions aimed at returning money or other securities of illegal origin to the financial and economic market, representing them the acquisition and possession of completely legal and normal, as well as the conduct of other operations that are illegal.",
          },
          {
            title:
              "6.2. To prevent operations of an illegal nature, the Service establishes certain requirements for all Applications created by the User:",
          },
          {
            title:
              "6.2.1. The sender and recipient of the Payment under the Application must be the same person. Using the services of the Service, transfers in favor of third parties are strictly prohibited.",
          },
          {
            title:
              "6.2.2. All contact information entered by the User in the Application, as well as other personal data transmitted by the User to the Service, must be up-to-date and completely reliable.",
          },
          {
            title:
              "6.2.3. It is strictly forbidden to create Applications by the User using anonymous proxy servers or any other anonymous Internet connections.",
          },
          {
            title: "6.3. To prevent transactions of an illegal nature, the Service:",
          },
          {
            title:
              "— Uses an internal system for automated analysis of transactions and behavior of the User (fraud prevention system), which stops all suspicious transactions of the User.",
          },
          {
            title: '— Adheres to the "Know Your Customer" policy.',
          },
          {
            title: "— Checks by all available means all the data provided by the User.",
          },
          {
            title:
              "6.4. The Service reserves the right to freeze the User's account and all current User Applications until the User receives copies of the User's identification documents, as well as the source of origin of the Electronic Units and other information necessary to verify the operation in the following cases:",
          },
          {
            title: "— If a violation of any of the requirements presented in paragraph 6.2 of this Agreement is detected.",
          },
          {
            title: "— When the User's Application is stopped by the fraud prevention system.",
          },
          {
            title:
              "— If there are reasonable suspicions from the administration of the Service that the user is trying to use the services of the Service for money laundering or for the purpose of carrying out any other illegal operations.",
          },
          {
            title:
              "— In turn, the User undertakes to provide the requested document within 7 working days from the receipt of a request for its provision, or request cancellation of the application.",
          },
          {
            title:
              "6.4.1. If the User refuses to provide the requested documents, the Service reserves the right to refuse the User further service and take the actions described in clause 4.10 of this agreement.",
          },
          {
            title:
              "6.5. The Service reserves the right to refuse the User further service and take the actions described in clause 4.10 of this agreement, after which it transfers all the User’s data available to the Service, as well as all available information about the User’s operations to law enforcement agencies in the following cases:",
          },
          {
            title:
              "— Detection of transactions whose purpose is money laundering, financing of terrorist organizations, fraud of any kind, as well as transactions aimed at carrying out any other illegal and illegal operations.",
          },
          {
            title:
              "— If the Service has a reasonable suspicion that the document provided by the User to identify the User is fake or invalid.",
          },
          {
            title:
              "— Receipt of information from authorized bodies on the incompetence of the User's possession of electronic units or financial resources and / or other information that makes it impossible for the Service to provide services to the User.",
          },
          {
            title:
              "— Identification of any actions or attempts to perform actions by the User aimed at providing any negative impact on the software and hardware complex of the Service.",
          },
          {
            title:
              "— Identification of any actions or attempts to commit actions by the User aimed at stealing databases and other tangible and intangible property of the Service.",
          },
          {
            title:
              "— Identification of any actions or attempts to perform actions by the User that can cause any physical, material and non-material harm to the Service.",
          },
          {
            title:
              "6.6. The Service reserves the right to check by all means available to it personal and any other information received from the User.",
          },
        ],
      },
      {
        number: "7",
        title: "Disclaimer",
        desc: [
          {
            title:
              "7.1. The Service offers its services around the clock and seven days a week and will strive to ensure that the software and hardware complex that implements the capabilities of the Service works without failures.",
          },
          {
            title:
              '7.2. The Service provides its services on an "as is" basis, as they are described on the pages of the Service\'s website and does not offer any additional warranties.',
          },
          {
            title:
              "7.3. Using the Service, the User agrees that the scope of the Service's liability is limited to the funds received from the User to fulfill the subject of this agreement, that the Service does not provide additional guarantees and does not bear any additional responsibility to the User, just as the User does not bear additional responsibility to the Service, except for the cases specified in clause 7.9.",
          },
          {
            title:
              "7.4. The Service will make every effort, but does not guarantee that its services will be available around the clock and daily. The Service does not bear any responsibility for losses, unearned profits and other costs of the User resulting from the impossibility of obtaining access to the site and services of the Service.",
          },
          {
            title:
              "7.5. The Service does not bear any responsibility for losses, lost profits and other costs of the User resulting from delays, errors or failures in making bank payments or electronic transfers.",
          },
          {
            title:
              "7.6. The Service does not bear any responsibility for losses, lost profits and other costs of the User, resulting from the User's erroneous expectations regarding the tariff rates of the Service, the profitability of transactions and other subjective factors.",
          },
          {
            title:
              "7.7. If the User provides erroneous data in the information about the details of the recipient of funds, the Service does not bear any responsibility for any adverse consequences or damage resulting from this error.",
          },
          {
            title:
              "7.8. Using the services of the Service, the User is solely responsible for the payment of taxes in accordance with the tax legislation of the country at his place of residence. The Service is not a tax agent and will not notify the User of any possible tax costs when using the services of the Service.",
          },
          {
            title:
              "7.9. The User guarantees indemnification of the Service (the management company, managers and employees) in cases of claims or claims directly or indirectly related to the User's use of the Service, with the exception of losses caused by the guilty (intentional or negligent) actions of the Service itself.",
          },
          {
            title:
              "7.10. The User guarantees that he is the owner or has legal grounds for the disposal of the amounts used in his transactions.",
          },
          {
            title:
              "7.11. The User undertakes to refrain from using the services of the Service for fraudulent and illegal transactions and agrees that any attempt to exchange fraudulent capital will be prosecuted to the fullest extent of the law. The actions of the User may be considered illegal in accordance with the laws of the country of residence of the User.",
          },
          {
            title:
              "7.12. The User undertakes not to falsify communication flows related to the operation of the Service, not to interfere with its software and / or hardware, and not to exert any other influence that could disrupt the normal operation of the Service, realizing that such actions will be subject to prosecution to the fullest extent law.",
          },
          {
            title:
              "7.13. The User acknowledges that the content of the Service website is protected by the laws on the protection of property rights, intellectual property and copyrights. Unauthorized use of this content is illegal.",
          },
          {
            title:
              "7.14. Neither the User nor the Service will be liable to each other for delays or failure to fulfill their obligations resulting from the occurrence of force majeure circumstances, including natural disasters, fire, flood, terrorist acts, power shifts, civil unrest, as well as the non-functioning of Electronic Settlement Systems, systems power supply, communication networks and Internet service providers.",
          },
          {
            title:
              "7.15. Electronic settlement systems and/or financial institutions are solely responsible for the funds entrusted to them by the User. The Service cannot be a party to an agreement between the Payment System and/or a financial institution and the User and is in no way responsible for the misuse or unauthorized use of the Electronic Settlement System by the User, as well as for the abuse by the User of the functionality of the Electronic Settlement System. Mutual rights and obligations of the User and the Electronic Settlement System and/or financial institution are regulated by the relevant agreements.",
          },
        ],
      },
      {
        number: "8",
        title: "The procedure for accepting claims and resolving disputes",
        desc: [
          {
            title:
              "8.1. Disputes and disagreements arising in the framework of the provision of services by the Service to the User are resolved through negotiations between the User and the administration of the Service, based on the provisions of this agreement.",
          },
          {
            title:
              "8.1.1. Any claims under this agreement can be accepted by the Service in electronic form by sending a message on the essence of the claim to the details (contact page) indicated on the Service website.",
          },
          {
            title:
              "8.2. If it is impossible to resolve emerging issues through negotiations, the dispute is resolved in accordance with the current legislation at the place of registration of the Service.",
          },
        ],
      },
    ],
  },

  footer: {
    desc: "A profitable and convenient service that provides an opportunity to convert the required amount from one currency to another online.",
    btn: "Exchange",
    link: {
      title: "Useful links",
      promo: "Promo code",
      faq: "FAQ",
      terms: "Terms of use",
      privacy: "Privacy Policy",
    },
  },
};
