import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CountDown from "../components/Home/Timer";
import { useNavigate } from "react-router-dom";
// import { ExchangeContext } from "../contexts/ExchangeContext";


const Wallet = (props) => {
  const nav = useNavigate();

  const date = new Date
  // Translation
  const { t } = useTranslation();
  const basePath = "navBar.";
  // let policyContent = [].concat(t(basePath + "policyContent", { returnObjects: true }));
  let firstCurrencyInner = localStorage.getItem("firstCurrencyInner");
  let secondCurrencyInner = localStorage.getItem("secondCurrencyInner");
  let amount = localStorage.getItem("amount");
  let id = localStorage.getItem("id");
  let wallet = localStorage.getItem("wallet");
  let toWallet = localStorage.getItem("toWallet");
  let resalt = localStorage.getItem("resalt");
  let timeStart = localStorage.getItem("id");
  // useEffect(() => {
  //   let timeStart = localStorage.getItem("id");
  //   if(!timeStart)nav('/')
  // },[])
  setInterval(() => {
    if(Number(Date.now())-Number(timeStart)>900000) {nav('/');localStorage.clear();} 
  }, 1000);
  return (
    <>
      <div className="wallet-container">
          <h3>№ {id}</h3>
          <img className='qr-photo' src={require(`../assets/img/qr/${firstCurrencyInner}.jpg`).default} alt="" />
          <h3 className="amount">{amount} {firstCurrencyInner}</h3>
          <h3 className="wallet"><b>{toWallet}</b></h3>
          <h3>↓</h3>
          <h3>{resalt} {secondCurrencyInner}</h3>
          <h3>{wallet}</h3>
          <h6>Выполните перевод в течении 15 минут</h6>
          <h8>{t(basePath + "etention")}</h8>
          {/* <CountDown hours={0} minutes={minutes} seconds={seccond} idd={id}/> */}
      </div>
    </>
  );
};

export default Wallet;
