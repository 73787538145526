export function Reducer(state, { type, payload }) {
  switch (type) {
    case "setToWallet":
      for (let index = 0; index < state.ourWallet.length; index++) {
        const element = state.ourWallet[index];
        if(element.coin === payload){
          return {
            ...state,
            toWallet: element.wallet || null,
          };
        }
      }

    case "setFirstCurrency":
      return {
        ...state,
        firstCurrency: payload || null,
      };
    case "setSecondCurrency":
      return {
        ...state,
        secondCurrency: payload || null,
      };
    case "setFirstCurrencyInner":
      return {
        ...state,
        firstCurrencyInner: payload || null,
      };
    case "setSecondCurrencyInner":
      return {
        ...state,
        secondCurrencyInner: payload || null,
      };
    case "setResalt":
      return {
        ...state,
        resalt: payload || 0,
      };
    case "setAmount":
      return {
        ...state,
        amount: payload || 0,
      };
    case "setActiveSelectCF":
      return {
        ...state,
        activeSelectCF: !state.activeSelectCF,
      };
    case "setActiveSelectCS":
      return {
        ...state,
        activeSelectCS: !state.activeSelectCS,
      };

    default:
      return state;
  }
}
