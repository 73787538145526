import React from "react";

const IconSafe = ({ width = "24px", height = "24px", fill = "#000", ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 512 512">
      <rect width="497" height="450" x="7.5" y="7.5" fill="#fb725a" rx="40" ry="40" opacity=".3" />
      <rect width="360" height="320" x="76" y="72.5" fill="#fb725a" rx="20" ry="20" opacity=".3" />
      <path fill="#fb725a" d="M76,457.5h75a0,0,0,0,1,0,0v27a20,20,0,0,1-20,20H96a20,20,0,0,1-20-20v-27A0,0,0,0,1,76,457.5Z" />
      <path
        fill={fill}
        d="M131,512H96a27.54,27.54,0,0,1-27.5-27.5v-27A7.5,7.5,0,0,1,76,450h75a7.5,7.5,0,0,1,7.5,7.5v27A27.54,27.54,0,0,1,131,512ZM83.5,465v19.5A12.52,12.52,0,0,0,96,497h35a12.52,12.52,0,0,0,12.5-12.5V465Z"
      />
      <path fill={fill} d="M361,457.5h75a0,0,0,0,1,0,0v27a20,20,0,0,1-20,20H381a20,20,0,0,1-20-20v-27A0,0,0,0,1,361,457.5Z" />
      <path
        fill={fill}
        d="M416,512H381a27.54,27.54,0,0,1-27.5-27.5v-27A7.5,7.5,0,0,1,361,450h75a7.5,7.5,0,0,1,7.5,7.5v27A27.54,27.54,0,0,1,416,512Zm-47.5-47v19.5A12.52,12.52,0,0,0,381,497h35a12.52,12.52,0,0,0,12.5-12.5V465Z"
      />
      <path fill={fill} d="M456,152.5H416a20,20,0,0,1,0-40h40a20,20,0,0,1,0,40Z" />
      <path
        fill={fill}
        d="M456,160H416a27.5,27.5,0,0,1,0-55h40a27.5,27.5,0,0,1,0,55Zm-40-40a12.5,12.5,0,0,0,0,25h40a12.5,12.5,0,0,0,0-25Z"
      />
      <path fill={fill} d="M456,352.5H416a20,20,0,0,1,0-40h40a20,20,0,0,1,0,40Z" />
      <path
        fill={fill}
        d="M456,360H416a27.5,27.5,0,0,1,0-55h40a27.5,27.5,0,0,1,0,55Zm-40-40a12.5,12.5,0,0,0,0,25h40a12.5,12.5,0,0,0,0-25Z"
      />
      <circle cx="256" cy="232.5" r="90" fill={fill} />
      <path
        fill={fill}
        d="M256,330a97.5,97.5,0,1,1,97.5-97.5A97.61,97.61,0,0,1,256,330Zm0-180a82.5,82.5,0,1,0,82.5,82.5A82.59,82.59,0,0,0,256,150Z"
      />
      <circle cx="256" cy="232.5" r="40" fill={fill} />
      <path
        fill={fill}
        d="M256,280a47.5,47.5,0,1,1,47.5-47.5A47.56,47.56,0,0,1,256,280Zm0-80a32.5,32.5,0,1,0,32.5,32.5A32.54,32.54,0,0,0,256,200Z"
      />
    </svg>
  );
};

export default IconSafe;
